import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';
import { defaultGlobalState } from 'providers/GlobalStateProvider';
import useWindowSize from 'hooks/useWindowSize';

const DeviceAdd = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [uuname, setUuname] = useState('');
	const [uunameError, setUunameError] = useState('');

	useEffect(() => {
		let showBottomNavigation = true;
		let showMobileStepper = false;
		if (state.bulkOnboarding) {
			showBottomNavigation = false;
			showMobileStepper = true;
		}

		dispatch({ headerTitle: t('deviceAdd.header'), showMobileStepper: showMobileStepper, showBottomNavigation: showBottomNavigation });
	}, [dispatch, state.bulkOnboarding, t]);

	const handleOnboard = async () => {
		setUunameError('');

		if (!uuname.length) {
			setUunameError(t('deviceAdd.error'));
		} else {
			if (state.bulkOnboarding) {
				dispatch({ uuname: uuname });

				navigate('/onboard/name');
			} else {
				delete defaultGlobalState.user;
				dispatch(defaultGlobalState);
				localStorage.removeItem('globalState');

				dispatch({ uuname: uuname });

				navigate('/onboard/customer');
			}
		}
	}

	return (
		<OnboardContainer hasPadding={true} title="" subtitle="" icon={null} cancelRoute="/devices" noAvatar={true} style={{ height: windowSize.height !== undefined ? windowSize.height - 119 : 0 }}>
			<Typography style={{ marginTop: 20 }}>{t('deviceAdd.text')}</Typography>

			<TextField
				id="uuname"
				label={t('main.deviceId')}
				value={uuname}
				variant="outlined"
				color="secondary"
				className={classes.textField}
				onChange={(event) => setUuname(event.target.value)}
				error={uunameError.length ? true : false}
				helperText={uunameError}
			/>

			<Box className={classes.buttonPanel} justifyContent="center">
				<Button className={classes.greenButton} onClick={() => handleOnboard()}>{t('deviceAdd.onboard')}</Button>
			</Box>
		</OnboardContainer>
	)
}

export default DeviceAdd;