import React, { useState } from 'react';
import { Container, Paper, Card, CardHeader, Avatar, CardContent, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';
import ConfirmDialog from 'components/ui/ConfirmDialog';

const OnboardContainer = ({ hasPadding, cancelRoute, title, subtitle, children, icon, noCancel, noAvatar, noConfirm, style }) => {
	const classes = mainStyles();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [showCancelDialog, setShowCancelDialog] = useState(false);

	const handleCancelDialog = () => {
		if (!noConfirm || noConfirm === undefined) {
			setShowCancelDialog(true);
		} else {
			handleOk();
		}
	}

	const handleCancel = () => {
		setShowCancelDialog(false);
	}

	const handleOk = () => {
		navigate(cancelRoute);
	}

	return (
		<Container component="main" maxWidth="lg" className={classes.container} style={style}>
			<Card elevation={0}>
				<CardHeader
					avatar={
						noAvatar === undefined ?
							<Avatar>{icon}</Avatar>
							: <></>
					}
					action={
						noCancel === undefined ?
							<IconButton onClick={() => handleCancelDialog()}>
								<CancelIcon sx={{ fontSize: 34 }} />
							</IconButton>
							: <></>
					}
					title={title}
					titleTypographyProps={{ textTransform: 'uppercase' }}
					subheader={subtitle}
				/>
				<CardContent>
					<Paper className={hasPadding ? classes.paper : classes.paperNoPadding} sx={{ width: '100%', overflow: 'scroll' }}>
						{children}
					</Paper>
				</CardContent>
			</Card>

			<ConfirmDialog visible={showCancelDialog} title={t('main.cancelHeader')} text={t('main.cancelText')} handleCancel={handleCancel} handleOk={handleOk} />
		</Container>
	)
}

export default OnboardContainer;