import React, { useState, useEffect } from 'react';
import { Typography, TextField, Box, Button } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';
import useGlobalState from '../hooks/useGlobalState';
import CircularLoader from 'components/ui/CircularLoader';

const DeviceDatafieldEdit = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const params = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [device, setDevice] = useState(null);
	const [name, setName] = useState('');
	const [unit, setUnit] = useState('');

	useEffect(() => {
		dispatch({ headerTitle: t('datafields.header'), showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch, t]);

	useEffect(() => {
		async function fetchData() {
			if (state.onboardedDevice) {
				setDevice(state.onboardedDevice);
				console.log(state.onboardedDevice);

				setLoading(false);
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSave = () => {

		navigate('/device/datafields/list');
	}

	return (
		<>
			{!loading ? (
				<OnboardContainer hasPadding={true} title={device.name} subtitle={device.uuname} icon={<TimelineIcon />} cancelRoute="/device">
					<Typography variant="h3" align="center">{t('datafields.header')}</Typography>

					<Typography style={{ marginTop: 20 }}>{t('datafields.text')} <b>{params.datafield}</b></Typography>
					<TextField
						id="name"
						label={t('main.name')}
						value={name}
						variant="outlined"
						color="secondary"
						className={classes.textField}
						onChange={(event) => setName(event.target.value)}
					/>

					<TextField
						id="name"
						label={t('main.device')}
						value={unit}
						variant="outlined"
						color="secondary"
						className={classes.textField}
						onChange={(event) => setUnit(event.target.value)}
					/>

					<Box className={classes.buttonPanel} justifyContent="center">
						<Button className={classes.greenButton} onClick={() => handleSave()}>{t('main.save')}</Button>
					</Box>

				</OnboardContainer>
			) : (<CircularLoader />)}
		</>
	)
}

export default DeviceDatafieldEdit;