import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

const styles = makeStyles(theme => ({
	dialogWidth: {
		maxWidth: 300,
	},
	checkbox: {
		padding: 8
	}
}))

const CustomDateTime = (props) => {
	const classes = styles();
	const { t } = useTranslation();

	const { openCustomDate, handleCloseDialog, handleCancelCustomDate } = props; //, from, to 
	// const [endDate, setEndDate] = useState(to ? moment(to) : moment());
	const [endDate, setEndDate] = useState(moment());
	// const [startDate, setStartDate] = useState(from ? moment(from) : moment().subtract(7, 'days'));
	const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
	// const period = useSelector(s => s.appState.period);

	useEffect(() => {
		if (props.period) {
			setStartDate(moment(props.period.from));
			setEndDate(moment(props.period.to));
		}
	}, [props.period])

	return <Dialog
		open={openCustomDate}
		onClose={handleCancelCustomDate}
		aria-labelledby='alert-dialog-title'
		aria-describedby='alert-dialog-description'>
		<DialogTitle id='alert-dialog-title'>{t('period.customHeader')}</DialogTitle>
		<DialogContent className={classes.dialogWidth}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<DateTimePicker
						autoOk
						ampm={false}
						margin={'normal'}
						clearable
						inputVariant={'outlined'}
						label={t('period.startDate')}
						mask={'__/__/____ __:__:__'}
						inputFormat={"dd/MM/yyyy HH:mm:ss"}
						placeholder="MM/DD/YYYY HH:mm:ss"
						value={startDate}
						onChange={value => { setStartDate(value) }}
						animateYearScrolling={false}
						color='primary'
						disableFuture
						dateRangeIcon={<DateRangeIcon />}
						timeIcon={<AccessTimeIcon />}
						rightArrowIcon={<KeyboardArrowRightIcon />}
						leftArrowIcon={<KeyboardArrowLeftIcon />}
						renderInput={(props) => <TextField {...props} style={{ marginTop: 10 }} />}
					/>
				</Grid>
				<Grid item xs={12}>
					<DateTimePicker
						autoOk
						ampm={false}
						margin={'normal'}
						clearable
						inputVariant={'outlined'}
						label={t('period.endDate')}
						mask={'__/__/____ __:__:__'}
						inputFormat={"dd/MM/yyyy HH:mm:ss"}
						placeholder="MM/DD/YYYY HH:mm:ss"
						value={endDate}
						onChange={value => { setEndDate(value) }}
						animateYearScrolling={false}
						color='primary'
						disableFuture
						dateRangeIcon={<DateRangeIcon />}
						timeIcon={<AccessTimeIcon />}
						rightArrowIcon={<KeyboardArrowRightIcon />}
						leftArrowIcon={<KeyboardArrowLeftIcon />}
						renderInput={(props) => <TextField {...props} style={{ marginTop: 10 }} />}
					/>
				</Grid>
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleCancelCustomDate} color='primary'>
				{t('period.cancel')}
			</Button>
			<Button onClick={() => { handleCloseDialog(endDate, startDate) }} color='primary' autoFocus>
				{t('period.apply')}
			</Button>
		</DialogActions>
	</Dialog>
}

export default CustomDateTime;