import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = (props) => {
	const { t } = useTranslation();

	const { visible, title, text, handleCancel, handleOk } = props;

	return (
		<Dialog
			maxWidth="xs"
			open={visible}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>
				{text}
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel} color="secondary">
					{t('main.no')}
				</Button>
				<Button onClick={handleOk} color="warning">
					{t('main.yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ConfirmDialog.propTypes = {
	visible: PropTypes.bool,
	title: PropTypes.string,
	text: PropTypes.string,
	handleCancel: PropTypes.func,
	handleOk: PropTypes.func,
};

export default ConfirmDialog;