import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import cookie from 'react-cookies';

import CircularLoader from 'components/ui/CircularLoader';
import { getAuth, getUser, setToken } from 'data/api';
import useGlobalState from 'hooks/useGlobalState';

const AuthUser = () => {
	const navigate = useNavigate();
	const { device, deviceType, token } = useParams();
	const [, dispatch] = useGlobalState();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			if (device && deviceType && token) {
				const result = await getAuth(token);

				if (result) {
					cookie.save('SESSION', result, { path: '/', expires: moment().add('30', 'days').toDate() });

					if (setToken()) {
						const userData = await getUser();

						if (userData) {
							dispatch({ user: userData });
						}

						navigate('/' + device + '/' + deviceType, { replace: true });
					}
				}
			}
		}

		fetchData();
	}, [dispatch, navigate, device, deviceType, token]);

	return (
		<>
			{loading ? (
				<CircularLoader />
			) : (
				<></>)}
		</>
	)
}

export default AuthUser;