import React, { useEffect, useState } from 'react';
import TimelineIcon from '@mui/icons-material/Timeline';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from 'hooks/useGlobalState';
import { getDeviceDataConverted } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';
import OnboardContainer from 'components/OnboardContainer';
import Period from 'components/Period';
import useWindowSize from 'hooks/useWindowSize';

const DeviceData = () => {
	const [state, dispatch] = useGlobalState();
	const params = useParams();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [series, setSeries] = useState([]);

	useEffect(() => {
		dispatch({ headerTitle: t('deviceData.header'), showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch, t]);

	useEffect(() => {
		console.log('fetch data effect');

		Highcharts.setOptions({
			time: {
				useUTC: false
			},
			lang: {
				decimalPoint: ',',
				thousandsSep: '.',
				months: t('deviceData.monthsLong', { returnObjects: true }),
				shortMonths: t('deviceData.monthsShort', { returnObjects: true }),
				weekdays: t('deviceData.weekdays', { returnObjects: true }),
				resetZoom: t('deviceData.resetZoom'),
			},
			accessibility: {
				enabled: false
			},
			credits: {
				enabled: false
			},
		});

		let newSeries = [];

		async function fetchData() {
			console.log('fetch data');

			setLoading(true);

			let data = await getDeviceDataConverted(state.onboardedDevice.uuid, state.period, params.datafield);

			if (data) {
				newSeries.push({
					yAxis: 0,
					type: 'spline',
					name: params.datafield,
					color: '#000',
					lineWidth: 1,
					marker: {
						enabled: false,
					},
					states: {
						hover: {
							enabled: true,
							lineWidth: 2,
						}
					},
					// tooltip: {
					// 	valueSuffix: ' ' + axis.config.unit ? axis.config.unit : ''
					// },
					data: data
				});
			}

			setSeries(newSeries);

			setLoading(false);
		}

		if (loading) {
			fetchData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useEffect(() => {
		setLoading(true);
	}, [state.period]);

	return (
		<OnboardContainer title={state.onboardedDevice.name} subtitle={state.onboardedDevice.uuname} icon={<TimelineIcon />} cancelRoute="/device" noConfirm={true} style={{ height: windowSize.height !== undefined ? windowSize.height - 119 : 0, overflow: 'scroll' }}>
			<>
				{!loading ? (
					<>
						<Period />

						<HighchartsReact
							highcharts={Highcharts}
							options={{
								chart: {
									height: 300,
									margin: [50, 20, 70, 50],
									backgroundColor: '#f5f5f5',
									zoomType: 'x',
									resetZoomButton: {
										position: {
											x: 0,
											y: -40
										}
									},
								},
								plotOptions: {
									series: {
										animation: false,
										turboThreshold: 0,
									},
									column: {
										pointPadding: 0.2,
										borderWidth: 0
									},
								},

								tooltip: {
									xDateFormat: '%d. %B %Y %H:%M:%S',
									shared: true,
									split: false,
									enabled: true,
									valueDecimals: 1,
									style: {
										fontSize: '16px',
									},
									useHTML: true,
									headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
									pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
										'<td style="text-align: right"><b>{point.y} {valueSuffix}</b></td></tr>',
									footerFormat: '</table>',
								},
								xAxis: {
									type: 'datetime',
									crosshair: {
										snap: true
									},
								},
								yAxis: [
									{
										title: {
											text: '',
										},
									},
								],
								title: {
									text: '',
								},
								series: series,
							}}
						/>
					</>
				) : <CircularLoader />}
			</>
		</OnboardContainer>
	)
}

export default DeviceData;