import React, { useEffect, useState, useRef } from 'react';//useCallback
import { Container, Box } from '@mui/material';
// import Webcam from "react-webcam";
import QrScanner from 'qr-scanner';
// import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';
import useGlobalState from '../hooks/useGlobalState';
import Alert from 'components/ui/Alert';
import useWindowSize from 'hooks/useWindowSize';

const ScanDevice = () => {
	const classes = mainStyles();
	const [, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const webcamRef = useRef(null);

	const [alertWrongOpen, setAlertWrongOpen] = useState(false);
	const [alertErrorOpen, setAlertErrorOpen] = useState(false);
	const [scanner, setScanner] = useState(null);

	useEffect(() => {
		dispatch({ headerTitle: t('scan.header'), device: null, deviceType: null, showMobileStepper: false, showBottomNavigation: true, bulkOnboarding: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		async function startScanner() {
			// const scan = useCallback(() => {
			let qrScanner = null;
			if (!webcamRef.current) return;
			// const imageData = webcamRef.current.getScreenshot();

			// QrScanner.scanImage(imageData, { returnDetailedScanResult: true, highlightScanRegion: true, highlightCodeOutline: true })
			// .then(result => console.log(result))
			// .catch(error => console.log(error || 'No QR code found.'));

			qrScanner = new QrScanner(
				webcamRef.current,
				result => {
					// console.log(result);
					const resultParts = result.data.split('/');
					if (resultParts.length === 6) {
						navigate('/' + resultParts[4] + '/' + resultParts[5]);
					}
				},
				{
					returnDetailedScanResult: true,
					// highlightScanRegion: true,
					// highlightCodeOutline: true,
					maxScansPerSecond: 5,
					calculateScanRegion: (video) => ({
						x: 0,
						y: 0,
						width: video.videoWidth,
						height: video.videoHeight,
						downScaledHeight: 0,
						downScaledWidth: 0,
					})
				});

			qrScanner.start().then(async () => {
				console.log('started scanner');
			}).catch(console.error);

			setScanner(qrScanner);
		}

		if (!scanner) {
			startScanner();
		}

		// .scanImage(webcamRef, { returnDetailedScanResult: true })
		// 	.then(result => {
		// 		const resultParts = result.split('/');
		// 		if (resultParts[3] !== 'onboard') {
		// 			setAlertWrongOpen(true);
		// 		} else {
		// 			navigate('/' + resultParts[4] + '/' + resultParts[5]);
		// 		}
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 		//setAlertErrorOpen(true);
		// 	});


		return () => {
			if (scanner) {
				scanner.destroy();
				setScanner(null);
			}
		}

	}, [navigate, scanner]);

	// const cancel = () => {

	// }

	const closeAlert = () => {
		setAlertErrorOpen(false);
		setAlertWrongOpen(false);
	}

	return (
		<Container component="main" maxWidth="lg" className={classes.containerWithPadding} style={{ height: windowSize.height !== undefined ? windowSize.height - 119 : 0, overflow: 'scroll' }}>
			<video id="qr-video" ref={webcamRef} style={{ width: '100%', height: 500, marginTop: 30 }} playsInline muted></video>
			{/* <Webcam
				style={{ width: '50%', marginTop: 30 }}
				ref={webcamRef}
				screenshotFormat="image/jpg"
				screenshotQuality={1}
				videoConstraints={{ facingMode: "environment" }}
			/> */}

			{/* <QrReader
				videoId="video"
				scanDelay={500}
				constraints={{ facingMode: 'environment' }}
				onResult={(result, error) => {
					if (!!result) {
						console.log(result.text);
						const resultParts = result.text.split('/');
						console.log(resultParts.length);

						if (resultParts[3] !== 'onboard') {
							// setAlertWrongOpen(true);
						} else if (resultParts.length === 6) {
							navigate('/' + resultParts[4] + '/' + resultParts[5]);
						}
					}

					if (!!error) {
						console.log(error);
					}
				}}
				style={{ width: '100%' }}
			/> */}

			<Box className={classes.buttonPanel} justifyContent="center">
				{/* <Button className={classes.greenButton} onClick={() => scan()}>Scan</Button> */}
				{/* <Button className={classes.redButton} onClick={() => cancel()}>Annuller</Button> */}
			</Box>

			<Alert text={t('scan.notRecognized')} visible={alertWrongOpen} handleClose={closeAlert} />
			<Alert text={t('scan.noQrFound')} visible={alertErrorOpen} handleClose={closeAlert} />
		</Container>
	)
}

export default ScanDevice;