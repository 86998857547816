import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import App from 'App';
import theme from 'styles/theme';
import GlobalStateProvider from 'providers/GlobalStateProvider';
import CircularLoader from 'components/ui/CircularLoader';

import './i18n';

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<CircularLoader />}>
			<GlobalStateProvider>
				<ThemeProvider theme={theme}>
					<CssBaseline>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<App />
						</LocalizationProvider>
					</CssBaseline>
				</ThemeProvider>
			</GlobalStateProvider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
