import React, { useState } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import EqualizerIcon from '@mui/icons-material/Equalizer';
import IconButton from '@mui/material/IconButton';
import { Box, Button, Typography, Hidden, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import cookie from 'react-cookies';
// import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import CustomDateTime from 'components/ui/CustomDateTime';
import { ReactComponent as ArrowPrev } from "assets/icons/arrow_prev_blue.svg";
import { ReactComponent as ArrowNext } from "assets/icons/arrow_next_blue.svg";
import mainStyles from 'styles/mainStyles';
import useGlobalState from 'hooks/useGlobalState';

const Period = () => {
	const classes = mainStyles();
	// const { uuid } = useParams();
	const [state, dispatch] = useGlobalState();
	const { t } = useTranslation();

	// const period = useSelector(s => s.appState.period);
	// const selectedPeriod = useSelector(s => s.appState.selectedPeriod);

	const [openCustomDate, setOpenCustomDate] = useState(null);

	// useEffect(() => {
	// 	const newPeriod = getPeriod(state.selectedPeriod);

	// 	dispatch({ period: newPeriod });
	// 	dispatch({ selectedPeriod: newPeriod.menuId });

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const renderCustomDateDialog = () => {
		return openCustomDate ? <CustomDateTime
			openCustomDate={openCustomDate}
			handleCloseDialog={handleCloseDialog}
			period={state.period ? state.period : null}
			handleCancelCustomDate={handleCancelCustomDate}
		/> : null;
	}

	const handleCloseDialog = (to, from) => {
		setOpenCustomDate(false);
		// setActionAnchor(null);
		handleSetDate(6, to, from);
	}

	const handleCancelCustomDate = () => {
		setOpenCustomDate(false);
	}

	const futureTester = (date) => {
		var todayDate = moment();
		var futureDate = moment(date).add('1', 'day');

		if (!todayDate.isAfter(futureDate)) {
			return true;
		} else {
			return false;
		}
	}

	const getPeriod = (menuId, newto, newfrom, newtimeTypeData) => {
		cookie.remove('period', { path: '/' });

		let thisfrom = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
		let thisto = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
		let thistimetype = 1;
		let thistimetypedata = 1;

		if (state.period) {
			thistimetype = state.period.timeType;
			thistimetypedata = state.period.timeTypeData;
		}

		if (newto && newfrom) {
			thisto = newto;
			thisfrom = newfrom;
		}

		dispatch({ selectedPeriod: menuId });

		if (!newto || !newfrom) {
			switch (menuId) {
				default:
				case 10:
					thisfrom = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
					thisto = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
					break;
				case 11:
					thisfrom = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
					thisto = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss');
					break;
				case 1:
					thisfrom = moment().startOf('week').format('YYYY-MM-DD HH:mm:ss');
					thisto = moment().endOf('week').format('YYYY-MM-DD HH:mm:ss');
					break;
				case 2:
					thisfrom = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
					thisto = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
					break;
				case 3:
					thisfrom = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
					thisto = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
					break;
				case 5:
					thisfrom = moment().subtract(89, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
					thisto = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
					break;
				case 6:
					thisfrom = moment(newfrom).format('YYYY-MM-DD HH:mm:ss');
					thisto = moment(newto).format('YYYY-MM-DD HH:mm:ss');
					break;
				case 7:
					thisfrom = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
					thisto = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
					break;
			}
		}

		switch (menuId) {
			default:
			case 10:
				thistimetype = 1;
				thistimetypedata = 1;
				break;
			case 11:
				thistimetype = 1;
				thistimetypedata = 1;
				break;
			case 1:
				thistimetype = 2;
				thistimetypedata = 3;
				break;
			case 2:
				thistimetype = 2;
				thistimetypedata = 3;
				break;
			case 3:
				thistimetype = 2;
				thistimetypedata = 3;
				break;
			case 5:
				thistimetype = 2;
				thistimetypedata = 3;
				break;
			case 6:
				if (moment(thisfrom).diff(moment(thisto), 'days') === 0) {
					thistimetype = 1;
					thistimetypedata = 1;
				} else {
					thistimetype = 2;
					thistimetypedata = 3;
				}
				break;
			case 7:
				thistimetype = 2;
				thistimetypedata = 3;
				break;
		}

		if (newtimeTypeData) {
			thistimetypedata = newtimeTypeData;
		}

		let newPeriod = {
			menuId: menuId,
			from: thisfrom,
			to: thisto,
			timeType: thistimetype,
			timeTypeData: thistimetypedata
		};

		cookie.save('period', newPeriod, { path: '/', expires: moment().add('1', 'day').toDate() })

		return newPeriod;
	}

	const goForward = () => {
		let thisfrom = 0;
		let thisto = 0;

		let checkFrom = state.period.from;
		let checkTo = state.period.to;

		switch (state.selectedPeriod) {
			default:
			case 10:
				thisfrom = moment(checkFrom).add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).add(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 11:
				thisfrom = moment(checkFrom).add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).add(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 1:
				thisfrom = moment(checkFrom).add(1, 'week').startOf('week').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).add(1, 'week').endOf('week').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 2:
				thisfrom = moment(checkFrom).add(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).add(6, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 3:
				thisfrom = moment(checkFrom).add(1, 'month').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).add(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 5:
				thisfrom = moment(checkFrom).add(89, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).add(89, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 7:
				thisfrom = moment(checkFrom).add(29, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).add(29, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
		}

		dispatch({ period: getPeriod(state.selectedPeriod, thisto, thisfrom) });
	}

	const goBack = () => {
		let thisfrom = 0;
		let thisto = 0;

		let checkFrom = state.period.from;
		let checkTo = state.period.to;

		switch (state.selectedPeriod) {
			default:
			case 10:
				thisfrom = moment(checkFrom).subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 11:
				thisfrom = moment(checkFrom).subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 1:
				thisfrom = moment(checkFrom).subtract(1, 'week').startOf('week').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).subtract(1, 'week').endOf('week').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 2:
				thisfrom = moment(checkFrom).subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).subtract(6, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 3:
				thisfrom = moment(checkFrom).subtract(1, 'month').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 5:
				thisfrom = moment(checkFrom).subtract(89, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).subtract(89, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 7:
				thisfrom = moment(checkFrom).subtract(29, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
				thisto = moment(checkTo).subtract(29, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
				break;
		}

		dispatch({ period: getPeriod(state.selectedPeriod, thisto, thisfrom) });
	}

	const generatePeriodDesc = () => {
		return (
			<>
				{moment(state.period.from).format('ll')}
				&nbsp;&nbsp;&nbsp;
				{` — `}
				&nbsp;&nbsp;&nbsp;
				{moment(state.period.to).format('ll')}
			</>
		)
	}

	const handleSetDate = (menuId, to, from) => {
		let timetypedata = 3;
		switch (menuId) {
			case 10: // Today
				from = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
				to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
				timetypedata = 1;
				break;
			case 11: // Yesterday
				from = moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss')
				to = moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DD HH:mm:ss')
				timetypedata = 1;
				break;
			case 1: // This week
				from = moment().startOf('week').startOf('day').format('YYYY-MM-DD HH:mm:ss')
				to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
				break;
			case 2: // Last 7 days
				from = moment().subtract(6, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss')
				to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
				break;
			case 3: // this month
				// from = moment().subtract(30, 'd').startOf('day')
				from = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
				to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
				break;
			// case 4: // this year
			// 	from = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss')
			// 	to = moment().format('YYYY-MM-DD HH:mm:ss')
			// 	break;
			case 5: // last 90 days
				from = moment().subtract(89, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss')
				to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
				break;
			case 7: // last 30 days
				from = moment().subtract(29, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss')
				to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
				break;
			case 6: //custom
				from = moment(from).format('YYYY-MM-DD HH:mm:ss')
				to = moment(to).format('YYYY-MM-DD HH:mm:ss')

				if (moment(from).diff(moment(to), 'days') === 0) {
					timetypedata = 1;
				}
				break;
			default:
				break;
		}

		dispatch({ period: getPeriod(menuId, to, from, timetypedata) });
	}

	return (
		<>
			{state.period ?
				<>
					<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.graphDatePickers}>
						<Box display="flex" justifyContent="center" alignItems="center">
							<IconButton onClick={goBack}>
								<ArrowPrev />
							</IconButton>
							<Typography className={classes.graphDatePickersDesc}>{generatePeriodDesc()}</Typography>
							<IconButton disabled={futureTester(state.period.from)} onClick={goForward}>
								<ArrowNext />
							</IconButton>
						</Box>
						<Box display="flex" justifyContent="center" alignItems="center">
							<Hidden only={['xs']}>
								<Button className={(state.selectedPeriod === 10 ? classes.periodButtonActive : classes.periodButton)} onClick={() => handleSetDate(10)}>{t('period.today')}</Button>
								<Button className={(state.selectedPeriod === 11 ? classes.periodButtonActive : classes.periodButton)} onClick={() => handleSetDate(11)}>{t('period.yesterday')}</Button>
								<Button className={(state.selectedPeriod === 2 ? classes.periodButtonActive : classes.periodButton)} onClick={() => handleSetDate(2)}>{t('period.last7days')}</Button>
								<Button className={(state.selectedPeriod === 7 ? classes.periodButtonActive : classes.periodButton)} onClick={() => handleSetDate(7)}>{t('period.last30days')}</Button>
								{/* <Button className={(selectedPeriod === 4 ? buildingClasses.periodButtonActive : buildingClasses.periodButton)} onClick={() => handleSetDate(4)}>År</Button> */}
								<Button className={(state.period.timeType === 6 ? classes.periodButtonActive : classes.periodButton)} onClick={() => setOpenCustomDate(true)}><CalendarTodayIcon /></Button>
								{/* <Button className={buildingClasses.periodButton} onClick={() => setOpenCustomData(true)}><EqualizerIcon /></Button> */}
							</Hidden>
							<Hidden smUp>
								<Select
									labelId="state-select-label"
									id="state-select"
									value={state.selectedPeriod}
									onChange={(e) => {
										if (e.target.value === 100) {
											setOpenCustomDate(true)
											// } else if (e.target.value === 200) {
											// 	setOpenCustomData(true)
										} else {
											handleSetDate(e.target.value);
										}
									}}
									style={{ width: '300px', marginTop: '20px' }}
									variant="outlined"
								>
									<MenuItem key={10} value={10}>{t('period.today')}</MenuItem>
									<MenuItem key={11} value={11}>{t('period.yesterday')}</MenuItem>
									<MenuItem key={11} value={2}>{t('period.last7days')}</MenuItem>
									<MenuItem key={11} value={7}>{t('period.last30days')}</MenuItem>
									<MenuItem key={11} value={100}>{t('period.custom')}</MenuItem>
									{/* <MenuItem key={11} value={200}>Data mængde</MenuItem> */}
								</Select>
							</Hidden>
						</Box>
					</Box>

					{renderCustomDateDialog()}
					{/* {renderCustomDataDialog()} */}
				</>
				: ""}
		</>
	)
}

export default Period;