import React, { useEffect, useState } from 'react';
import { Container, Paper, Card, CardHeader, Avatar, CardContent, Typography, Button, List, ListItem, ListItemText, ListItemIcon, IconButton, Menu, MenuItem, Grid, Divider } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TimelineIcon from '@mui/icons-material/Timeline';
import ImageIcon from '@mui/icons-material/Image';
// import ListIcon from '@mui/icons-material/List';
import { useNavigate, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';
import useGlobalState from '../hooks/useGlobalState';
import CircularLoader from 'components/ui/CircularLoader';
import { getDeviceByUuid, getLatestData, getOrg } from 'data/api';
import useWindowSize from 'hooks/useWindowSize';

const Device = () => {
	const classes = mainStyles();
	const navigate = useNavigate();
	const params = useParams();
	const [state, dispatch] = useGlobalState();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [height, setHeight] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(true);
	const [device, setDevice] = useState(null);
	const [org, setOrg] = useState(null);
	const [latestData, setLatestData] = useState([]);
	const [datafields, setDatafields] = useState([]);
	const [mapPosition, setMapPosition] = useState(null);

	useEffect(() => {
		//leaflet hack to fix marker images
		delete L.Icon.Default.prototype._getIconUrl;

		L.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
			iconUrl: require('leaflet/dist/images/marker-icon.png').default,
			shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
		});
	}, []);

	useEffect(() => {
		dispatch({ headerTitle: t('main.device'), showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch, t]);

	useEffect(() => {
		if (windowSize.height !== undefined) {
			setHeight(windowSize.height - 119);
		}
	}, [windowSize]);

	useEffect(() => {
		async function fetchData() {
			let deviceData = null;
			if (params.uuid) {
				deviceData = await getDeviceByUuid(params.uuid);
			} else {
				deviceData = state.onboardedDevice;
			}

			if (deviceData) {
				// console.log(deviceData);
				setDevice(deviceData);

				dispatch({ onboardedDevice: deviceData });

				setMapPosition(null);

				setDatafields(deviceData.dataKeys);

				let newLatestData = [];
				await deviceData.dataKeys.reduce(async (dataKeypromise, dataKey) => {
					await dataKeypromise;

					const d = await getLatestData(deviceData.uuid, dataKey.key);

					if (d.status === 200 && d.data?.length) {
						let value = d.data.pop()[dataKey.key];
						if (!isNaN(value)) {
							newLatestData.push({ label: dataKey.key, value: value, unit: dataKey.unit });
						}
					}
				}, Promise.resolve());

				setLatestData(newLatestData);

				const orgData = await getOrg(deviceData.registry?.org?.uuid);

				if (orgData) {
					setOrg(orgData);
				}
			}

			setLoading(false);
		}

		if (!params.uuid && !state.onboardedDevice) {
			navigate('/scan');
		} else {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleActionClick = (event) => {
		setAnchorEl(event.currentTarget);
	}

	const handleActionClose = () => {
		setAnchorEl(null);
	}

	const handleEditName = () => {
		handleActionClose();

		navigate('/device/name');
	}

	const handleEditDatafields = () => {
		handleActionClose();

		navigate('/device/datafields/list');
	}

	const handleImages = () => {

		handleActionClose();
	}

	const handleViewData = (datafield) => {
		handleActionClose();

		navigate('/device/data/' + datafield);
	}

	return (
		<Container component="main" maxWidth="lg" className={classes.containerWithPadding} style={{ height: height, overflow: 'scroll' }}>
			{device && !loading ? (
				<>
					<Card elevation={0}>
						<CardHeader
							avatar={
								<Avatar>
									<DeviceHubIcon htmlColor="#ED1C24" />
								</Avatar>
							}
							action={
								<IconButton aria-label="settings" onClick={handleActionClick}>
									<MoreVertIcon />
								</IconButton>
							}
							title={device.name}
							subheader={device.uuname}
						/>
						<CardContent>
							<Paper className={classes.paperLessPadding}>
								{org ? (
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<List style={{ padding: 0 }}>
												<ListItem style={{ padding: 0 }}>
													<ListItemText
														primary={
															<Typography style={{ fontSize: '12px', color: '#747474' }}>{t('customer.customer')}</Typography>
														}
														secondary={
															<Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#333334' }}>{org.name}</Typography>
														}
													/>
												</ListItem>
											</List>
										</Grid>
										<Grid item xs={6}>
											<List style={{ padding: 0, margin: 0 }}>
												{org.address.length ?
													<ListItem style={{ padding: 0 }}>
														<ListItemText
															style={{ padding: 0, margin: 0 }}
															primary={
																<Typography style={{ fontSize: '12px', color: '#747474' }}>{t('main.place')}</Typography>

															}
															secondary={
																<Typography
																	style={{ fontSize: '14px', color: '#333334', whiteSpace: 'pre-line' }}
																>
																	{(org.address.length ? org.address + '\n' : '') + (org.zip.length && org.city.length ? org.zip + '  ' + org.city : '')}
																</Typography>
															}
														/>
													</ListItem>
													: <></>}
											</List>
										</Grid>
										<Grid item xs={6}>
											{mapPosition ?
												<MapContainer
													center={mapPosition}
													zoom={16}
													zoomControl={false}
													scrollWheelZoom={false}
													style={{ width: '100%', height: '200px', marginBottom: 30 }}>
													<TileLayer
														attribution=""
														url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
													/>
													<Marker position={mapPosition} />
												</MapContainer>
												: <></>}
										</Grid>
									</Grid>
								) : (<></>)}

								<Grid container style={{ marginTop: 30 }}>
									<Grid item xs={12} lg={8}>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Grid container spacing={1}>
											{datafields.map(d => {
												const data = latestData.find(o => o.label === d.key);
												return (
													<Grid item xs={6} key={d.key}>
														<Button className={classes.greyButtonBox} onClick={() => handleViewData(d.key)}>
															<Typography align="center" style={{ fontSize: '14px' }}>{d.key}</Typography>
															<Typography align="center" style={{ fontSize: '18px' }}>{data !== undefined ? new Intl.NumberFormat('da-DK').format(data.value) : '-'}</Typography>
														</Button>
													</Grid>
												)
											})}
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</CardContent>
					</Card>
					<Menu
						id="vert-menu"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleActionClose}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<MenuItem>{t('main.edit')}</MenuItem>
						<Divider />
						<MenuItem onClick={handleEditName}>
							<ListItemIcon>
								<DeviceHubIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>{t('main.deviceName')}</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleEditDatafields}>
							<ListItemIcon>
								<TimelineIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>{t('main.datafields')}</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleImages}>
							<ListItemIcon>
								<ImageIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>{t('main.images')}</ListItemText>
						</MenuItem>
					</Menu>
				</>
			) : <CircularLoader />}
		</Container>
	)
}

export default Device;