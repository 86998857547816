import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: '#D9D9D9',
		}, secondary: {
			main: '#898584',
		},
		background: {
			default: '#fff',
		},
	},
	typography: {
		h3: {
			fontSize: '1.6rem',
			fontWeight: 'bold',
		},
	},
	overrides: {
	}
});

export default theme;