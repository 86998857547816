import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const Alert = (props) => {
	const { visible, text, handleClose } = props;

	return (
		<div>
			<Dialog
				open={visible}
				onClose={handleClose}
				aria-labelledby="alert-title"
				aria-describedby="alert-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{text}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="filled">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

Alert.propTypes = {
	visible: PropTypes.bool,
	text: PropTypes.string,
	handleClose: PropTypes.func,
};

export default Alert;