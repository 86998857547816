import React, { useState, useCallback } from 'react';
import { Typography, Container, Paper, TextField, Grid, Button, Collapse, Link } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import loginStyles from 'styles/loginStyles';
import { resetPassword, confirmPassword as bConfirmPass } from 'data/api';
import useEventListener from 'hooks/useEventListener';
import CircularLoader from 'components/ui/CircularLoader';

const ForgotPassword = () => {
	const classes = loginStyles();
	const params = useParams();
	let navigate = useNavigate();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState([]);
	const [passwordRequest, setPasswordRequest] = useState(false);
	const [passwordReset, setPasswordReset] = useState(false);

	const errorMessages = useCallback(code => {
		switch (code) {
			case 0:
				return t('forgotPassword.noEmptyPassword');
			case 1:
				return t('forgotPassword.passwordLength');
			case 2:
				return t('forgotPassword.passwordNoMatch');
			case 404:
				return t('forgotPassword.emailNoMatch');
			case 404.1:
				return t('forgotPassword.userDontExist');
			default:
				return '';
		}
	}, [t]);

	const handleValidation = useCallback(() => {
		let errorCode = []
		if (password === '' && confirmPassword === '') {
			errorCode.push(0)
		}
		if (password.length < 8) {
			errorCode.push(1)
		}
		if (password !== confirmPassword) {
			errorCode.push(2)
		}
		if (errorCode.length === 0) {
			return true
		} else {
			setError(true)
			setErrorMessage(errorCode.map(c => (
				<Typography color="error" key={c}>{errorMessages(c)}</Typography>
			)))
			return false
		}
	}, [confirmPassword, errorMessages, password]);

	const confirmPass = useCallback(async () => {
		if (handleValidation()) {
			setLoading(true);

			let session = await bConfirmPass({
				newPassword: password,
				token: params.token,
			})

			setLoading(false);

			if (session === 200) {
				setPasswordReset(true);
			} else {
				setError(true)
				setErrorMessage([<Typography color="error">{errorMessages(404.1)}</Typography>])
			}
		}
	}, [errorMessages, handleValidation, params.token, password])

	const keyPressHandler = useCallback(
		(event) => {
			if (params.token)
				if (event.key === 'Enter') {
					confirmPass();
				}

		},
		[confirmPass, params.token]
	);

	useEventListener('keypress', keyPressHandler);

	const resetPass = async () => {
		setLoading(true);

		let session = await resetPassword(username);

		setLoading(false);

		if (session === 200) {
			setPasswordRequest(true);
		} else {
			setError(true);
			setErrorMessage([<Typography color="error">{errorMessages(session)}</Typography>]);
		}
	}

	const handleChangePassword = e => {
		setPassword(e.target.value);

		if (error) {
			setError(false);
			setErrorMessage([]);
		}
	}
	const handleChangeUsername = e => {
		setUsername(e.target.value)

		if (error) {
			setError(false);
			setErrorMessage([]);
		}
	}

	const handleChangeConfirmPassword = e => {
		setConfirmPassword(e.target.value)

		if (error) {
			setError(false);
			setErrorMessage([]);
		}
	}

	return (
		<Container component="main" maxWidth="sm">
			<div className={classes.logoWrapper}>
				<img src='/logo.svg' alt="Senti" className={classes.logoImage} />
			</div>

			<Paper elevation={3} className={classes.loginPaper}>
				<Typography className={classes.loginHeader}>{t('main.header')}</Typography>

				<Typography variant="body1" style={{ marginTop: 20 }}>{params.token ? t('forgotPassword.confirm') : t('forgotPassword.reset')}</Typography>

				<Collapse in={error} style={{ marginTop: 20 }}>{errorMessage}</Collapse>

				{!loading ?
					<>
						<Grid container>
							<Grid item justifyContent={'center'} xs={12}>
								<Collapse in={passwordReset}>
									{params.token ? (
										<Typography color="green">
											{t('forgotPassword.passwordChanged')}
										</Typography>
									) : null}
								</Collapse>
							</Grid>
							<Grid item xs={12}>
								{!passwordRequest &&
									params.token ? null : (
										<TextField
											id={'username'}
											autoFocus
											label={t('login.username')}
											value={username}
											onChange={handleChangeUsername}
											margin="normal"
											fullWidth
											className={classes.textField}
										/>
									)}
							</Grid>

							{params.token ? (
								<>
									<Grid item xs={12}>
										<TextField
											fullWidth
											id={'password'}
											type={'password'}
											label={t('login.password')}
											value={password}
											className={classes.textField}
											onChange={handleChangePassword}
											margin="normal"
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											id={'confirmpassword'}
											type={'password'}
											label={t('forgotPassword.confirmPassword')}
											value={confirmPassword}
											className={classes.textField}
											onChange={handleChangeConfirmPassword}
											margin="normal"
										/>
									</Grid>
								</>
							) : null}

							<Collapse in={passwordRequest}>
								<Grid item xs={12}>
									<Typography color="success">{t('forgotPassword.emailSent')}</Typography>
								</Grid>
							</Collapse>
						</Grid>
						<Grid item xs={12} justifyContent={'center'}>
							<Collapse in={!passwordRequest}>
								{!params.token ? (
									<Button
										className={classes.loginButton}
										variant={'contained'}
										onClick={resetPass}
									>
										{t('forgotPassword.resetPassword')}
									</Button>
								) : !passwordReset ? (
									<Button
										className={classes.loginButton}
										variant={'contained'}
										onClick={confirmPass}
									>
										{t('forgotPassword.changePassword')}
									</Button>
								) : (<Button
									className={classes.loginButton}
									variant={'contained'}
									onClick={() =>
										navigate('/login')
									}
								>
									{t('forgotPassword.gotoLogin')}
								</Button>)}
							</Collapse>
						</Grid>
						<Grid item xs={12} justifyContent={'center'} style={{ margin: '32px 0px' }}>
							<Collapse in={!passwordReset}>
								<Link href="/login">
									{t('forgotPassword.gotoLogin')}
								</Link>
							</Collapse>
						</Grid>
					</>
					: <CircularLoader />}
			</Paper>
		</Container>
	)
}

export default ForgotPassword;