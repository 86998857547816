import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box, Button, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';

const OnboardChooseImages = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const inputRef = useRef(null);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [images, setImages] = useState([]);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		let activeStep = 5;
		if (state.bulkOnboarding) {
			activeStep = 3;
		}

		dispatch({ headerTitle: t('images.header'), showMobileStepper: true, showBottomNavigation: false, activeStep: activeStep });
	}, [dispatch, state.bulkOnboarding, t]);

	useEffect(() => {
		if (state.selectedImages) {
			setImages(state.selectedImages);
		}
	}, [state.selectedImages]);

	const handleAddImage = () => {
		inputRef.current.click();
	}

	const handleUpload = (event) => {
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = () => {
			// console.log(reader.result);
			let newImages = [...images];
			newImages.push(reader.result);
			setImages(newImages);

			dispatch({ selectedImages: newImages });

			// const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
		};
		reader.readAsDataURL(file);
	}

	const handleDelete = (event, index) => {
		let newImages = images.filter(el => { return el !== images[index] });
		setImages(newImages);
		dispatch({ selectedImages: newImages });
	}

	return (
		<OnboardContainer hasPadding={true} title={t('main.deviceId')} subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			<Typography variant="h3" align="center">{t('images.subheader')}</Typography>

			<Typography style={{ marginTop: 20 }}>{t('images.text')}</Typography>

			<div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
				<Grid container spacing={2}>
					{images.map((image, index) => {
						return (
							<Grid item xs={6} key={index} style={{ marginTop: 30, alignContent: 'center', justifyContent: 'center' }}>
								<div style={{ position: 'relative', width: '100px', height: '100px', backgroundImage: 'url(' + image + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
									<div style={{ width: '30px', height: '30px', position: 'absolute', top: '-15px', right: '-15px', backgroundColor: '#686465', borderRadius: '50%', paddingLeft: '3px', paddingTop: '3px' }} onClick={(event) => handleDelete(event, index)}>
										<CloseIcon htmlColor="#fff" />
									</div>
								</div>
							</Grid>
						);
					})}
				</Grid>
			</div>

			<input
				style={{ display: 'none' }}
				ref={inputRef}
				type="file"
				name="myImage"
				accept="image/jpeg,image/png"
				onChange={(event) => handleUpload(event)}
			/>

			<Box className={classes.buttonPanel} justifyContent="center">
				<Button className={classes.greenButton} onClick={() => handleAddImage()}>{t('images.add')}</Button>
			</Box>
		</OnboardContainer>
	)
}

export default OnboardChooseImages;