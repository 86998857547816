import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'App.css';
import RequireAuth from 'helpers/RequireAuth';
import Login from 'routes/Login';
import ForgotPassword from 'routes/ForgotPassword';
import MainContainer from 'components/MainContainer';
import AuthUser from 'routes/AuthUser';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/auth/:device/:deviceType/:token" element={<AuthUser />} />
				<Route path="/login" element={<Login />}></Route>
				<Route path="/password/reset/:lang">
					<Route path=":token" element={<ForgotPassword />} />
					<Route path="" element={<ForgotPassword />} />
				</Route>
				<Route path="*" element={<RequireAuth><MainContainer /></RequireAuth>}></Route>
				{/* <Route path="/password/confirm/:lang/:token" element={<ConfirmUser />}></Route> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;

