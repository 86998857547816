import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography, TextField } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import { getDeviceTypes } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';
import OnboardContainer from 'components/OnboardContainer';
import useWindowSize from 'hooks/useWindowSize';

const OnboardChooseDeviceType = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [deviceTypes, setDeviceTypes] = useState([]);
	const [deviceTypesFiltered, setDeviceTypesFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [selectedUuid, setSelectedUuid] = useState(null);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: t('deviceType.header'), showMobileStepper: true, showBottomNavigation: false, activeStep: 1 });
	}, [dispatch, t]);

	useEffect(() => {
		async function fetchData() {
			const dtData = await getDeviceTypes();

			if (dtData) {
				setDeviceTypes(dtData);
				setDeviceTypesFiltered(dtData);

				let foundSelected = undefined;
				if (state.selectedDeviceType) {
					foundSelected = dtData.find(o => o.uuid === state.selectedDeviceType);
				}

				if (foundSelected !== undefined) {
					setSelectedUuid(foundSelected.uuid);

					dispatch({ selectedDeviceType: foundSelected.uuid });

					const index = dtData.findIndex(o => o.uuid === foundSelected.uuid);

					setPage(Math.floor(index / rowsPerPage));
				} else if (state.deviceType?.length) {
					let foundScanned = dtData.find(o => o.name === state.deviceType);

					//test for COMA- prefix if the device type is not found
					if (foundScanned === undefined) {
						foundScanned = dtData.find(o => o.name === 'COMA-' + state.deviceType);
					}

					if (foundScanned !== undefined) {
						dispatch({ selectedDeviceType: foundScanned.uuid });
					}

					navigate('/onboard/registry');
				}
			}

			setLoading(false);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.deviceType]);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deviceTypesFiltered.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		const rpp = parseInt(event.target.value, 10);
		setRowsPerPage(rpp);

		if (selectedUuid && event.target.value !== -1) {
			const index = deviceTypes.findIndex(o => o.uuid === selectedUuid);

			setPage(Math.floor(index / rpp));
		} else {
			setPage(0);
		}
	}

	const handleRowClick = (event, uuid) => {
		setSelectedUuid(uuid);

		dispatch({ selectedDeviceType: uuid });
	}

	const handleSearch = (event) => {
		setPage(0);

		let newDeviceTypes = deviceTypes.filter(dt =>
			dt.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
			dt.description?.toLowerCase().includes(event.target.value.toLowerCase())
		);
		setDeviceTypesFiltered(newDeviceTypes);
	}

	return (
		<OnboardContainer hasPadding={false} title={t('main.deviceId')} subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			{!loading ? (
				<>
					<TextField
						id="table-search"
						label={t('deviceType.search')}
						type="search"
						variant="filled"
						color="secondary"
						InputProps={{ className: classes.searchFieldInput }}
						sx={{ input: { color: '#000' } }}
						className={classes.searchField}
						onChange={(event) => handleSearch(event)}
					/>
					<TableContainer sx={{ height: (windowSize.height !== undefined ? windowSize.height - 370 : 0) }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell style={{ backgroundColor: '#D9D9D9' }}><Typography>{t('deviceType.deviceType')}</Typography></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
									? deviceTypesFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: deviceTypesFiltered
								).map((dt) => (
									<TableRow key={dt.uuid} onClick={(event) => handleRowClick(event, dt.uuid)} style={{ cursor: 'pointer' }}>
										<TableCell component="th" scope="row" style={{ backgroundColor: (selectedUuid === dt.uuid ? '#DDE4ED' : '#F6F6F6') }}>
											<Typography>{dt.name}</Typography>
										</TableCell>
									</TableRow>
								))}

								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						style={{ backgroundColor: '#D9D9D9' }}
						rowsPerPageOptions={[5, 10, 25, { label: 'Alle', value: -1 }]}
						component="div"
						count={deviceTypesFiltered.length}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage=""
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			) : <CircularLoader />}
		</OnboardContainer>
	)
}

export default OnboardChooseDeviceType;