import React, { useState } from 'react';
import { Typography, Container, Paper, TextField, Grid, Button, Divider, Link } from '@mui/material';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';

import loginStyles from 'styles/loginStyles';
import { setToken, loginUser, getUser, getUserInternal } from 'data/api';
import useEventListener from 'hooks/useEventListener';
import CircularLoader from 'components/ui/CircularLoader';
import useGlobalState from 'hooks/useGlobalState';

const Login = () => {
	const classes = loginStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const [, dispatch] = useGlobalState();
	const { t } = useTranslation();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loggingIn, setLoggingIn] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const handleLogin = async () => {
		setLoggingIn(true);

		setErrorMsg('');

		await loginUser(username, password).then(async rs => {
			if (rs) {
				if (rs.error) {
					let msg = '';
					switch (rs.error) {
						case 'login.accountDeleted':
							msg = t('login.accountDeleted');
							break;
						case 'login.accountNotActive':
							msg = t('login.accountNotActive');
							break;
						case 'login.accountDeactivated':
							msg = t('login.accountDeactivated');
							break;
						case 'login.wrongPassword':
							msg = t('login.wrongPassword');
							break;
						case 'login.accountNotFound':
							msg = t('login.accountNotFound');
							break;
						case 'login.missingOrganisation':
							msg = t('login.missingOrganisation');
							break;
						case 'login.missingUsername':
							msg = t('login.missingUsername');
							break;
						case 'login.missingPassword':
							msg = t('login.missingPassword');
							break;
						default:
						case 'login.unknownError':
							msg = t('login.unknownError');
							break;
					}

					setErrorMsg(msg);
					setLoggingIn(false);
				} else {
					cookie.save('SESSION', rs, { path: '/', expires: moment().add('30', 'days').toDate() });

					if (setToken()) {
						const userData = await getUser();
	
						if (userData) {
							dispatch({ user: userData });

							const userInternal = await getUserInternal(userData.uuid);

							let language = 'en';
							if (userInternal.internal.sentibuilding?.settings?.language !== undefined) {
								language = userInternal.internal.sentibuilding.settings.language;
							}

							dispatch({ language: language });
						}

						navigate(location.state?.from?.pathname || '/', { replace: true });
					}
				}
			} else {
				setErrorMsg(t('login.couldNotLogin'));

				setLoggingIn(false);
			}
		});
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleLogin();
		}
	};

	useEventListener('keypress', handleKeyPress);

	return (
		<Container component="main" maxWidth="sm">
			<div className={classes.logoWrapper}>
				<img src='/logo.svg' alt="Senti" className={classes.logoImage} />
			</div>

			<Paper elevation={3} className={classes.loginPaper}>
				<Typography className={classes.loginHeader}>{t('main.header')}</Typography>

				{errorMsg ? <Typography variant="body1" color="error" style={{ marginTop: 20 }}>{errorMsg}</Typography> : ""}

				{!loggingIn ?
					<>
						<Grid container>
							<Grid item xs={12}>
								<TextField
									autoFocus
									id="username"
									label={t('login.username')}
									variant="outlined"
									className={classes.textField}
									onChange={(event) => setUsername(event.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="password"
									label={t('login.password')}
									type="password"
									variant="outlined"
									className={classes.textField}
									onChange={(event) => setPassword(event.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button onClick={handleLogin} variant="contained" className={classes.loginButton}>{t('login.login')}</Button>
							</Grid>
						</Grid>
					</>
					: <CircularLoader />}

				<Divider />

				<div className={classes.linksWrapper}>
					<Grid container>
						<Grid item xs={12}>
							<Link href="/password/reset/da" color="inherit" className={classes.forgotPasswordLink}>{t('login.forgotPassword')}</Link>
						</Grid>
						<Grid item xs={12}>
							{t('login.needAccount')} • <Link href="/password/reset/da" color="inherit" className={classes.createAccountLink}>{t('login.createAccount')}</Link>
						</Grid>
					</Grid>
				</div>
			</Paper>

			<div className={classes.bottomWrapper}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Link href="/" color="#000" variant="body2">{t('login.cookiePolicy')}</Link> • <Link href="/" color="#000" variant="body2">{t('login.personPolicy')}</Link>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='body2' className={classes.copyrightText}>©2016-{moment().year()} {t('login.copyright')} {t('poweredBy')}</Typography>
					</Grid>
				</Grid>

				<Typography variant='body2' className={classes.bottomText2}>{t('login.bottomText')}</Typography>
			</div>
		</Container>
	)
}

export default Login;