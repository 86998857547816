import { Typography, Button, Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import mainStyles from 'styles/mainStyles';
import { defaultGlobalState } from 'providers/GlobalStateProvider';
import useGlobalState from '../hooks/useGlobalState';
import OnboardContainer from 'components/OnboardContainer';

const OnboardDone = () => {
	const { uuid } = useParams();
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleGoToDevice = () => {
		delete defaultGlobalState.user;
		dispatch(defaultGlobalState);

		dispatch({ bulkOnboarding: false });

		localStorage.removeItem('globalState');

		navigate('/device/' + uuid);
	}

	const handleOnboardMore = () => {
		dispatch({ bulkOnboarding: true, activeStep: 0, device: null, uuname: null, selectedName: null, selectedDescription: null });

		navigate('/scan');
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan" noCancel={true}>
			<Typography align='center' variant='h3' style={{ marginTop: 20, marginBottom: 50 }}>{t('finish.success')}</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Button className={classes.greenButtonNoWidth} onClick={() => handleGoToDevice()} style={{ marginRight: 10 }}>{t('finish.goto')}</Button>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Button className={classes.greenButtonNoWidth} onClick={() => handleOnboardMore()}>{t('finish.continue')}</Button>
				</Grid>
			</Grid>
		</OnboardContainer>
	)

}

export default OnboardDone;