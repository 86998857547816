import React, { useEffect, useReducer } from 'react';
import moment from 'moment';

let reducer = (info, newInfo) => {
	if (newInfo === null) {
		localStorage.removeItem("globalState");
		return defaultGlobalState;
	}

	return { ...info, ...newInfo };
};

export const defaultGlobalState = {
	bulkOnboarding: false,
	showMobileStepper: false,
	showBottomNavigation: true,
	activeStep: 0,
	headerTitle: '',
	deviceType: null,
	uuname: null,
	device: null,
	user: null,
	language: null,
	owner: null,
	selectedCustomer: null,
	selectedDeviceType: null,
	selectedRegistry: null,
	selectedName: null,
	selectedDescription: null,
	selectedTool: null,
	selectedTemplate: null,
	selectedBuilding: null,
	selectedImages: null,
	selectedPackage: null,
	selectedDatafields: null,
	selectedTitles: null,
	selectedUnitTypes: null,
	selectedConversionFactors: null,
	selectedAlarmRecipients: null,
	onboardedDevice: null,
	period: { from: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'), timeTypeData: 1 },
	selectedPeriod: 10,
};

export const GlobalStateContext = React.createContext(defaultGlobalState);
export const DispatchStateContext = React.createContext(undefined);

const localState = JSON.parse(localStorage.getItem("globalState"));

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, localState || defaultGlobalState);

	useEffect(() => {
		let saveState = { ...state };
		delete saveState.selectedImages; //can't save in local storage because of the size
		localStorage.setItem("globalState", JSON.stringify(saveState));
	}, [state]);

	return (
		<GlobalStateContext.Provider value={state}>
			<DispatchStateContext.Provider value={dispatch}>{children}</DispatchStateContext.Provider>
		</GlobalStateContext.Provider>
	);
};

export default GlobalStateProvider;