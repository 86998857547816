import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography, TextField } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import { getOrgs } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';
import OnboardContainer from 'components/OnboardContainer';
import useWindowSize from 'hooks/useWindowSize';

const OnboardChooseCustomer = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [orgs, setOrgs] = useState([]);
	const [orgsFiltered, setOrgsFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [selectedUuid, setSelectedUuid] = useState(null);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: t('customer.header'), showMobileStepper: true, showBottomNavigation: false, activeStep: 0 });
	}, [dispatch, t]);

	useEffect(() => {
		console.log('useEffect');

		async function fetchData() {
			const orgsData = await getOrgs();

			if (orgsData) {
				setOrgs(orgsData);
				setOrgsFiltered(orgsData);
			}

			let found = undefined;
			if (state.selectedCustomer) {
				found = orgsData.find(o => o.uuid === state.selectedCustomer);
			} else if (state.device) {
				found = orgsData.find(o => o.uuname === state.device.data.registry.org.uuname);
			}

			if (found !== undefined) {
				setSelectedUuid(found.uuid);

				dispatch({ selectedCustomer: found.uuid });

				const index = orgsData.findIndex(o => o.uuid === found.uuid);

				setPage(Math.floor(index / rowsPerPage));
			}

			setLoading(false);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.device]);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgsFiltered.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		const rpp = parseInt(event.target.value, 10);
		setRowsPerPage(rpp);

		if (selectedUuid && event.target.value !== -1) {
			const index = orgs.findIndex(o => o.uuid === selectedUuid);

			setPage(Math.floor(index / rpp));
		} else {
			setPage(0);
		}
	}

	const handleRowClick = (event, uuid) => {
		setSelectedUuid(uuid);

		dispatch({ selectedCustomer: uuid });
	}

	const handleSearch = (event) => {
		setPage(0);

		let newOrgs = orgs.filter(o =>
			o.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
			o.address?.toLowerCase().includes(event.target.value.toLowerCase()) ||
			o.zip?.toLowerCase().includes(event.target.value.toLowerCase()) ||
			o.city?.toLowerCase().includes(event.target.value.toLowerCase()) ||
			o.aux?.cvr?.toLowerCase().includes(event.target.value.toLowerCase())
		);
		setOrgsFiltered(newOrgs);
	}

	return (
		<OnboardContainer hasPadding={false} title={t('main.deviceId')} subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			{!loading ? (
				<>
					<TextField
						id="table-search"
						label={t('customer.search')}
						type="search"
						variant="filled"
						color="secondary"
						InputProps={{ className: classes.searchFieldInput }}
						sx={{ input: { color: '#000' } }}
						className={classes.searchField}
						onChange={(event) => handleSearch(event)}
					/>
					<TableContainer sx={{ height: (windowSize.height !== undefined ? windowSize.height - 370 : 0) }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell style={{ backgroundColor: '#D9D9D9' }}><Typography>{t('customer.customer')}</Typography></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
									? orgsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: orgsFiltered
								).map((org) => (
									<TableRow key={org.uuid} onClick={(event) => handleRowClick(event, org.uuid)} style={{ cursor: 'pointer' }}>
										<TableCell component="th" scope="row" style={{ backgroundColor: (selectedUuid === org.uuid ? '#DDE4ED' : '#F6F6F6') }}>
											<Typography>{org.name}</Typography>
											<Typography variant="body2">{org.address?.length && org.zip?.length && org.city?.length ? org.address + ', ' + org.zip + ' ' + org.city : ''}</Typography>
										</TableCell>
									</TableRow>
								))}

								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						style={{ backgroundColor: '#D9D9D9' }}
						rowsPerPageOptions={[5, 10, 25, { label: 'Alle', value: -1 }]}
						component="div"
						count={orgsFiltered.length}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage=""
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			) : <CircularLoader />}
		</OnboardContainer>

	)
}

export default OnboardChooseCustomer;