import { create } from 'apisauce';
import cookie from 'react-cookies';
import moment from 'moment';

let backendHost = 'https://services.senti.cloud/core';
let databrokerHost = 'https://services.senti.cloud/databroker';
let buildingBackendHost = 'https://services.senti.cloud/senti-building-backend';
let microbizzHost = 'https://services.senti.cloud/microbizz';
let eventbrokerHost = 'https://services.senti.cloud/eventbroker';

const hostname = window && window.location && window.location.hostname;
let deviceConfigHost = 'https://services.senti.cloud/device-configuration';
if (hostname === 'localhost') {
	// deviceConfigHost = 'http://localhost:3057';
}

const coreApi = create({
	baseURL: backendHost,
	timout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
});

const databrokerApi = create({
	baseURL: databrokerHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

const buildingApi = create({
	baseURL: buildingBackendHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

const microbizzApi = create({
	baseURL: microbizzHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

const deviceConfigApi = create({
	baseURL: deviceConfigHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

const eventBrokerApi = create({
	baseURL: eventbrokerHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

const addressApi = create({
	baseURL: 'https://dawa.aws.dk',
	timeout: 30000,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
	mode: 'no-cors',
});

export const setToken = () => {
	try {
		let token = cookie.load('SESSION').token;

		coreApi.setHeader('Authorization', 'Bearer ' + token);
		databrokerApi.setHeader('Authorization', 'Bearer ' + token);
		buildingApi.setHeader('Authorization', 'Bearer ' + token);
		microbizzApi.setHeader('Authorization', 'Bearer ' + token);
		deviceConfigApi.setHeader('Authorization', 'Bearer ' + token);
		eventBrokerApi.setHeader('Authorization', 'Bearer ' + token);

		return true;
	} catch (error) {
		return false;
	}
};

export const setWlHost = () => {
	let wlHost = window.location.hostname;
	if (hostname === 'localhost') {
		wlHost = 'comadan.config.senti.cloud';
	}
	coreApi.setHeader('wlHost', wlHost);
	databrokerApi.setHeader('wlHost', wlHost);
	buildingApi.setHeader('wlHost', wlHost);
	microbizzApi.setHeader('wlHost', wlHost);
	eventBrokerApi.setHeader('wlHost', wlHost);
	deviceConfigApi.setHeader('wlHost', wlHost);
};

// CORE API

export const loginUser = async (username, password) => {
	const session = await coreApi.post('/v2/auth/basic', JSON.stringify({ username: username, password: password })).then(rs => rs.data);
	return session;
}

export const logoutUser = async () => {
	let session = cookie.load('SESSION');
	let data = await coreApi.delete('/v2/auth/' + session.token);

	cookie.remove('SESSION');
	localStorage.removeItem('globalState');

	return data;
}

export const getUsers = async () => {
	var data = await coreApi.get('/v2/entity/users').then(rs => rs.data);
	return data;
}

export const getUser = async () => {
	var data = await coreApi.get('/v2/auth/user').then(rs => rs.data);
	return data;
}

export const getUserInternal = async (uuid) => {
	let data = await coreApi.get('/v2/entity/user/' + uuid + '/internal').then(rs => rs.data);
	return data;
}

export const getAuth = async (token) => {
	var data = await coreApi.get('/v2/auth/' + token).then(rs => rs.data);
	return data;
}

export const resetPassword = async (email) => {
	let status = await coreApi.post('/v2/entity/user/forgotpassword', { email: email }).then(rs => rs.status);
	return status;
}

export const confirmPassword = async (obj) => {
	let status = await coreApi.post('/v2/entity/user/forgotpassword/set', obj).then(rs => rs.status);
	return status;
}

export const getOrgs = async () => {
	let data = await coreApi.get('/v2/entity/organisations').then(rs => rs.data);
	return data;
}

export const getOrg = async (uuid) => {
	let data = await coreApi.get('/v2/entity/organisation/' + uuid).then(rs => rs.data);
	return data;
}

// DATABROKER API

export const getRegistriesByOrg = async (orgUuid) => {
	let data = await databrokerApi.get('/v2/registries/' + orgUuid).then(rs => rs.data);
	return data;
}

export const getDeviceTypes = async () => {
	let data = await databrokerApi.get('/v2/devicetypes').then(rs => rs.data);
	return data;
}

export const getDeviceType = async (uuid, type) => {
	let data = await deviceConfigApi.get('/devicetype/' + uuid + '/' + type).then(rs => rs.data);
	return data;
}

// export const getDeviceByUuname = async (uuname) => {
// 	let result = await databrokerApi.get('/v2/deviceByUUname/' + uuname).then(rs => rs);
// 	return result;
// }

export const getDeviceByUuid = async (uuid) => {
	let data = await databrokerApi.get('/v2/device/' + uuid).then(rs => rs.data);
	return data;
}

export const updateDevice = async (postData) => {
	const data = await databrokerApi.put('/v2/device', postData).then(rs => rs.data);
	return data;
}

export const getDevices = async () => {
	let data = await databrokerApi.get('/v2/devices').then(rs => rs.data);
	return data;
}

export const getLatestData = async (uuid, datafield) => {
	let data = await databrokerApi.get('/v2/devicedata-clean/' + uuid + '/' + datafield + '/latest/0').then(rs => rs);
	return data;
}

export const getDeviceDataConverted = async (device, period, type, cf, cfStandard) => {
	let useStandardCF = true
	if (typeof cfStandard !== 'undefined') {
		useStandardCF = (parseInt(cfStandard) === 0) ? false : true;
	}
	let cloudFunction = 0;
	if (useStandardCF) {
		cloudFunction = 121; //per day
		if (period.timeTypeData === 1) {
			cloudFunction = 0; //raw
		} else if (period.timeTypeData === 3) {
			cloudFunction = 122; // per hour
		}
	}

	if (typeof cf !== 'undefined') {
		if (typeof cf === 'object') {
			cloudFunction = cf.join(',') + ',' + cloudFunction;
		} else {
			cloudFunction = cf;
		}
	}

	let data = await databrokerApi.get('/v2/devicedata-clean/' + device + '/' + type + '/' + period.from + '/' + period.to + '/' + cloudFunction).then(rs => rs.data);

	let convertedData = [];
	if (data) {
		// eslint-disable-next-line array-callback-return
		Object.keys(data).map(key => {
			if (data[key][type] !== null && data[key]['datetime'] !== null) {
				convertedData.push({ x: moment(data[key]['datetime']).valueOf(), y: parseFloat(data[key][type]) })
			}
		});
	}

	return convertedData;
};

// ADDRESS API

export const addressLookup = (address) => {
	let data = addressApi.get('/adresser?q=' + address).then(result => result.data);
	return data;
}

export const locationLookup = (lat, long) => {
	let data = addressApi.get('/adgangsadresser/reverse?x=' + long + '&y=' + lat + '&struktur=mini').then(result => result.data);
	return data;
}

// BUILDING API

export const getAllCollectionsFromServer = async () => {
	let data = await buildingApi.get('/collections').then(rs => rs.data);
	return data;
}

export const addCollection = async (postData) => {
	let data = await buildingApi.post('/collection/', postData).then(rs => rs.data);
	return data;
}

export const setCollectionPermissions = async (uuid, permissions) => {
	let data = await buildingApi.post('/permissions', { uuid: uuid, orgs: permissions }).then(rs => rs.data);
	return data;
}


export const addCollectionWidget = async (uuid, postData) => {
	let data = await buildingApi.post('/collection/' + uuid + '/widget', postData).then(rs => rs.data);
	return data;
}

export const getSettings = async () => {
	let data = await buildingApi.get('/settings').then(rs => rs.data);
	return data;
}

// MICROBIZZ

export const getTemplates = async (owner) => {
	let data = await microbizzApi.get('/v2/templates/' + owner).then(rs => rs.data);
	return data;
};

export const getTools = async (owner, uuid) => {
	let data = await microbizzApi.get('/v2/tools/' + owner + '/' + uuid).then(rs => rs.data);
	return data;
};

export const getMicrobizzDevice = async (owner, uuid) => {
	let data = await microbizzApi.get('/v2/device/' + owner + '/' + uuid).then(rs => rs.data);
	return data;
};

export const updateMicrobizzDevice = async (owner, uuid, putData) => {
	let data = await microbizzApi.put('/v2/device/' + owner + '/' + uuid, putData).then(rs => rs.data);
	return data;
};

// DEVICE CONFIG BACKEND

export const getDeviceByUuname = async (uuname) => {
	let data = await deviceConfigApi.get('/device/' + uuname).then(rs => rs);
	return data;
};

export const onboard = async (postData) => {
	let data = await deviceConfigApi.post('/onboard', postData).then(rs => rs);
	return data;
};

export const getAllPackages = async () => {
	let data = await deviceConfigApi.get('/v2/templates').then(rs => rs.data);
	return data;
};

export const getPackages = async (deviceType) => {
	let data = await deviceConfigApi.get('/v2/templates/' + deviceType).then(rs => rs.data);
	return data;
};

export const getPackage = async (uuid) => {
	let data = await deviceConfigApi.get('/v2/template/' + uuid).then(rs => rs.data);
	return data;
};

export const getPackageDefinitions = async (uuid, postData) => {
	let data = await deviceConfigApi.post('/v2/template/' + uuid, postData).then(rs => rs.data);
	return data;
};

//EVENTBROKER

export const getRule = async (uuid) => {
	let data = await eventBrokerApi.get('/v2/rule/' + uuid).then(rs => rs.data);
	return data;
};

export const addRule = async (postData) => {
	let data = await eventBrokerApi.post('/v2/rule', postData).then(rs => rs.data);
	return data;
};

export const updateRule = async (uuid, postData) => {
	let data = await eventBrokerApi.put('/v2/rule/' + uuid, postData).then(rs => rs.data);
	return data;
};

export const addAction = async (postData) => {
	let data = await eventBrokerApi.post('/v2/action', postData).then(rs => rs.data);
	return data;
};

setToken();
setWlHost();
