import React, { useState, useEffect } from 'react';
import { Typography, TextField, Box, Button } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useNavigate, useParams } from 'react-router-dom';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';
import useGlobalState from '../hooks/useGlobalState';
import CircularLoader from 'components/ui/CircularLoader';
import { getSettings, getMicrobizzDevice, getRule, addRule, updateRule, addAction } from 'data/api';//updateMicrobizzDevice
import useWindowSize from 'hooks/useWindowSize';

const DeviceAlarm = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const params = useParams();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [mbDevice, setMbDevice] = useState(null);
	const [rule, setRule] = useState(null);
	const [owner, setOwner] = useState(null);
	const [max, setMax] = useState('');
	const [maxError, setMaxError] = useState('');
	const [min, setMin] = useState('');
	const [minError, setMinError] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		dispatch({ headerTitle: t('alarms.header'), showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch, t]);

	useEffect(() => {
		async function fetchData() {
			const settings = await getSettings();

			const ownerSetting = settings.find(d => d.key === 'microbizzowner');

			if (ownerSetting !== undefined) {
				setOwner(ownerSetting);

				const mbDeviceData = await getMicrobizzDevice(ownerSetting.value, state.onboardedDevice.uuid);

				if (mbDeviceData.status === 'ok') {
					setMbDevice(mbDeviceData.result);

					if (mbDeviceData?.result?.toolId === null || mbDeviceData?.result?.templateId === null) {
						setError(t('alarms.error'));
					} else if (mbDeviceData.result.eventUUID) {
						const ruleData = await getRule(mbDeviceData.result.eventUUID);
						if (ruleData) {
							setRule(ruleData.result);

							const conditions = ruleData.result.condition.filter(o => o.metric === params.datafield);

							// if (conditions?.length === 2) {
							const maxCondition = conditions.find(o => o.operation === '>');
							if (maxCondition !== undefined) {
								setMax(maxCondition.qualifier);
							}

							const minCondition = conditions.find(o => o.operation === '<');
							if (minCondition !== undefined) {
								setMin(minCondition.qualifier);
							}
							// }
						}
					}
				}
			}

			setLoading(false);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSave = async () => {
		setMaxError('');
		setMinError('');

		if (!max.length && !min.length) {
			setMaxError(t('alarms.minMaxError'));
			setMinError(t('alarms.minMaxError'));
		} else {
			let condition = [];

			if (max.length && min.length) {
				condition = [
					{
						"metric": params.datafield,
						"operation": ">",
						"qualifier": max
					},
					{
						"logical": "||"
					},
					{
						"metric": params.datafield,
						"operation": "<",
						"qualifier": min
					}
				];
			} else if (max.length) {
				condition = [
					{
						"metric": params.datafield,
						"operation": ">",
						"qualifier": max
					}
				];
			} else if (min.length) {
				condition = [
					{
						"metric": params.datafield,
						"operation": "<",
						"qualifier": min
					}
				];
			}

			if (!mbDevice.eventUUID) { //create rule
				let session = cookie.load('SESSION');

				const actionJson =
				{
					"url": "/microbizz/v2/todo/" + owner.value,
					"type": "post",
					"baseURL": "https://services.senti.cloud",
					"headers": {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + session.token
					},
					"extconfig": {}
				};

				// add rule
				let ruleData = {};
				ruleData.name = state.onboardedDevice.name;
				ruleData.condition = condition;
				ruleData.config = { "when": { "every": { "m": "5" }, "first": { "m": "0" } }, "notificationStrategy": 1 };
				ruleData.deviceUUID = state.onboardedDevice.uuid;

				const ruleAddData = await addRule(ruleData);

				if (!ruleAddData) {
					//show error
				} else {
					console.log(ruleAddData);

					// update microbizz
					// let mbDeviceNew = { ...mbDevice };
					// mbDeviceNew.eventUUID = ruleAddData.result.uuid;

					// const microbizzData = await updateMicrobizzDevice(owner.value, state.onboardedDevice.uuid, mbDeviceNew);

					// if (microbizzData) {
					// 	console.log(microbizzData);
					// }
				}

				// add action
				let actionData = {};
				actionData.ruleUUID = ruleAddData.result.uuid;
				actionData.type = 12;
				actionData.state = 1;
				actionData.host = 'comadan.senti.cloud';
				actionData.config = actionJson;

				const addActionData = await addAction(actionData);

				if (addActionData) {
					console.log(addActionData);
				}
			} else { // update rule
				console.log('update rule');

				let newRule = { ...rule };
				let firstKey = null;
				let lastKey = null;
				newRule.condition.forEach((r, index) => {
					if (r.metric === params.datafield) {
						if (!firstKey) {
							firstKey = index;
						} else {
							lastKey = index;
						}
					}
				});

				if (firstKey !== null && lastKey === null) {
					newRule.condition.splice(firstKey + 1, 1);
					newRule.condition.splice(firstKey, 1);
				} else if (firstKey !== null && lastKey !== null) {
					newRule.condition.splice(lastKey, 1);
					newRule.condition.splice(firstKey + 1, 1);
					newRule.condition.splice(firstKey, 1);
				}

				if (newRule.condition.length && newRule.condition.at(-1).logical === undefined) {
					newRule.condition.push({ "logical": "||" });
				}

				newRule.condition = newRule.condition.concat(condition);

				const updateRuleData = await updateRule(rule.uuid, newRule);
				console.log(updateRuleData);
			}

			navigate('/device/datafields/list');
		}
	}

	return (
		<>
			{!loading ? (
				<OnboardContainer hasPadding={true} title={state.onboardedDevice.name} subtitle={state.onboardedDevice.uuname} icon={<TimelineIcon />} cancelRoute="/device" noConfirm={true} style={{ height: windowSize.height !== undefined ? windowSize.height - 119 : 0 }}>
					<Typography variant="h3" align="center">{t('alarms.header')}</Typography>

					{!error.length ?
						<>
							<Typography style={{ marginTop: 20 }}>{t('alarms.text')} <b>{params.datafield}</b></Typography>

							<TextField
								id="max"
								label={t('alarms.max')}
								value={max}
								variant="outlined"
								color="secondary"
								className={classes.textField}
								onChange={(event) => setMax(event.target.value)}
								error={maxError.length ? true : false}
								helperText={maxError}
							/>

							<TextField
								id="min"
								label={t('alarms.min')}
								value={min}
								variant="outlined"
								color="secondary"
								className={classes.textField}
								onChange={(event) => setMin(event.target.value)}
								error={minError.length ? true : false}
								helperText={minError}
							/>

							<Box className={classes.buttonPanel} justifyContent="center">
								<Button className={classes.greenButton} onClick={() => handleSave()}>{t('main.save')}</Button>
							</Box>
						</>
						:
						<Typography color="error" style={{ marginTop: 30 }}>{error}</Typography>}
				</OnboardContainer>
			) : (<CircularLoader />)}
		</>
	)
}

export default DeviceAlarm;