import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box, Button } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';
import { getDeviceByUuid, updateDevice } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';
import useWindowSize from 'hooks/useWindowSize';

const DeviceChooseName = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');

	useEffect(() => {
		if (!state.onboardedDevice) {
			navigate('/devices');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: t('device.header'), showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch, t]);

	useEffect(() => {
		setName(state.onboardedDevice.name);
		setDesc(state.onboardedDevice.description);
	}, [state.onboardedDevice]);

	const handleSave = async () => {
		setLoading(true);

		const deviceData = await getDeviceByUuid(state.onboardedDevice.uuid);

		if (deviceData) {
			let newDeviceData = { ...deviceData };
			newDeviceData.name = name;
			newDeviceData.description = desc;

			const deviceUpdateData = await updateDevice(newDeviceData);

			if (deviceUpdateData) {
				dispatch({ onboardedDevice: deviceUpdateData });

				navigate('/device');
			}
		}

		setLoading(false);
	}

	return (
		<OnboardContainer hasPadding={true} title={state.onboardedDevice.name} subtitle={state.onboardedDevice.uuname} icon={<DeviceHubIcon />} cancelRoute="/device" noConfirm={true} style={{ height: windowSize.height !== undefined ? windowSize.height - 119 : 0 }}>
			<Typography variant="h3" align="center">{t('device.subheader')}</Typography>

			{!loading ?
				<>
					<TextField
						id="name"
						label={t('main.name')}
						value={name}
						variant="outlined"
						color="secondary"
						className={classes.textField}
						onChange={(event) => setName(event.target.value)}
					/>

					<TextField
						multiline
						id="desc"
						label={t('main.desc')}
						value={desc}
						variant="outlined"
						color="secondary"
						className={classes.textField}
						onChange={(event) => setDesc(event.target.value)}
						inputProps={{
							style: {
								minHeight: '100px',
								height: "200px",
							},
						}}
					/>

					<Box className={classes.buttonPanel} justifyContent="center">
						<Button className={classes.greenButton} onClick={() => handleSave()}>{t('main.save')}</Button>
					</Box>
				</> : <CircularLoader />}
		</OnboardContainer>
	)
}

export default DeviceChooseName;