import React, { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, InputLabel, Typography, Autocomplete, Chip, TextField, Grid, Divider, IconButton, Box, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import OnboardContainer from 'components/OnboardContainer';
import CircularLoader from 'components/ui/CircularLoader';
import { getAllPackages, getPackage, getPackages, getDeviceType, getUsers } from 'data/api';
import mainStyles from 'styles/mainStyles';

const OnboardChoosePackage = () => {
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const classes = mainStyles();

	const [loading, setLoading] = useState(true);
	const [infoIsOpen, setInfoIsOpen] = useState(false);
	const [infoText, setInfoText] = useState('');
	const [templates, setTemplates] = useState([]);
	const [template, setTemplate] = useState('');
	const [fields, setFields] = useState([]);
	const [datafields, setDatafields] = useState({});
	const [titles, setTitles] = useState({});
	const [unitTypes, setUnitTypes] = useState({});
	const [conversionFactors, setConversionFactors] = useState({});
	const [dataTypeFields, setDataTypeFields] = useState([]);
	const [alarms, setAlarms] = useState([]);
	const [alarmRecipients, setAlarmRecipients] = useState([]);
	const [alarmRecipientsError, setAlarmRecipientsError] = useState('');
	const [alarmUsers, setAlarmUsers] = useState([]);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: t('package.header'), showMobileStepper: true, showBottomNavigation: false, activeStep: 6 });
	}, [dispatch, t]);

	useEffect(() => {
		async function fetchData() {
			let templateData = null;
			if (!state.selectedDeviceType) {
				templateData = await getAllPackages();
			} else {
				templateData = await getPackages(state.selectedDeviceType);
			}

			if (templateData?.status === 'ok') {
				setTemplates(templateData.result);

				//if there is only one template, select it
				if (templateData.result.length === 1) {
					await handleTemplateChange(templateData.result[0].uuid);
				}

				const deviceTypeData = await getDeviceType(state.selectedDeviceType, state.deviceType);

				if (deviceTypeData) {
					setDataTypeFields(deviceTypeData.outbound);
				}

				if (state.selectedPackage) {
					setTemplate(state.selectedPackage);
					handleTemplateChange(state.selectedPackage);
				}

				if (state.selectedDatafields) {
					setDatafields(state.selectedDatafields);
				}

				if (state.selectedTitles) {
					setTitles(state.selectedTitles);
				}

				if (state.selectedUnitTypes) {
					setUnitTypes(state.selectedUnitTypes);
				}

				if (state.selectedConversionFactors) {
					setConversionFactors(state.selectedConversionFactors);
				}

				const usersData = await getUsers();

				if (usersData) {
					let newRecipients = [];

					let newUsers = [];
					usersData.forEach(d => {
						const u = {
							uuid: d.uuid,
							id: d.uuid,
							email: d.email,
							firstName: d.firstName,
							lastName: d.lastName,
							display: d.firstName + ' ' + d.lastName + ' (' + d.email + ')'
						};
						newUsers.push(u);

						if (state.selectedAlarmRecipients) {
							if (state.selectedAlarmRecipients.find(r => r.uuid === d.uuid)) {
								newRecipients.push(u);
							}
						}
					});

					setAlarmUsers(newUsers);
					setAlarmRecipients(newRecipients);
				}
			}

			setLoading(false);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTemplateChange = async (value) => {
		setTemplate(value);

		const packageData = await getPackage(value);

		if (packageData?.status === 'ok') {
			let newFields = [];
			let newDataFields = state.selectedDatafields ? { ...state.selectedDatafields } : {};
			let newTitles = state.selectedTitles ? { ...state.selectedTitles } : {};
			let newUnitTypes = state.selectedUnitTypes ? { ...state.selectedUnitTypes } : {};
			let newConversionFactors = state.selectedConversionFactors ? { ...state.conversionFactors } : {};

			packageData.result.widgets.forEach(widget => {
				if (widget.fields) {
					widget.fields.forEach(field => {
						newFields.push(field);

						if (field.defaultField !== null && newDataFields[field.uuid] === undefined) {
							newDataFields[field.uuid] = field.defaultField;
						}

						if (field.title?.length && newTitles[field.uuid] === undefined) {
							newTitles[field.uuid] = field.title;
						}

						if (field.unit?.length && newUnitTypes[field.uuid] === undefined) {
							newUnitTypes[field.uuid] = field.unit;
						}

						if (field.conversionFactor?.length && newConversionFactors[field.uuid] === undefined) {
							newConversionFactors[field.uuid] = field.conversionFactor;
						}
					});
				}
			});

			setFields(newFields);

			setDatafields(newDataFields);
			setTitles(newTitles);
			setUnitTypes(newUnitTypes);
			setConversionFactors(newConversionFactors);

			dispatch({ selectedDatafields: newDataFields, selectedTitles: newTitles, selectedUnitTypes: newUnitTypes, selectedConversionFactors: newConversionFactors });

			if (packageData.result.events) {
				setAlarms(packageData.result.events);
			} else {
				setAlarms([]);
			}
		}

		dispatch({ selectedPackage: value });
	}

	const handleFieldChange = (value, uuid) => {
		let newDataFields = { ...datafields };

		newDataFields[uuid] = value;

		setDatafields(newDataFields);

		dispatch({ selectedDatafields: newDataFields });
	}

	const handleFieldTitleChange = (value, uuid) => {
		let newTitles = { ...titles };

		newTitles[uuid] = value;

		setTitles(newTitles);

		dispatch({ selectedTitles: newTitles });
	}

	const handleFieldUnitChange = (value, uuid) => {
		let newUnitTypes = { ...unitTypes };

		newUnitTypes[uuid] = value;

		setUnitTypes(newUnitTypes);

		dispatch({ selectedUnitTypes: newUnitTypes });
	}

	const handleFieldConversionFactorChange = (value, uuid) => {
		let newConversionFactors = { ...conversionFactors };

		newConversionFactors[uuid] = value;

		setConversionFactors(newConversionFactors);

		dispatch({ setConversionFactors: newConversionFactors });
	}

	const handleRecipientsChange = (e, newValue) => {
		setAlarmRecipientsError('');

		if (typeof e.target.value === 'string' && !isEmail(e.target.value)) {
			setAlarmRecipientsError(t('package.emailError'));
		} else {
			let newRecipients = [];
			newValue.forEach(recipient => {
				newRecipients.push({
					uuid: recipient.uuid,
					name: recipient.firstName + ' ' + recipient.lastName,
					email: recipient.email
				});
			});

			setAlarmRecipients(newRecipients);

			dispatch({ selectedAlarmRecipients: newRecipients });
		}
	}

	const handleShowInfo = (fieldUuid) => {
		const field = fields.find(f => f.uuid === fieldUuid);
		setInfoText(field.conversionFactorInfo);
		setInfoIsOpen(true);
	}

	const handleInfoClose = () => {
		setInfoText('');
		setInfoIsOpen(false);
	}

	return (
		<OnboardContainer hasPadding={true} title={t('main.deviceId')} subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			{!loading ? (
				<>
					<FormControl fullWidth style={{ marginTop: 20 }}>
						<InputLabel id="select-tool-label">{t('package.dropdown')}</InputLabel>

						<Select
							labelId="select-template-label"
							id="select-template"
							value={template}
							label={t('package.dropdown')}
							onChange={(event) => handleTemplateChange(event.target.value)}
						>
							{templates.map(d => {
								return <MenuItem key={d.uuid} value={d.uuid}>{d.name}</MenuItem>;

							})}
						</Select>
					</FormControl>

					{fields.length ?
						<>
							<Typography variant='h5' align='center' style={{ marginTop: 30 }}>{t('package.datafields')}</Typography>
							{fields.map(field => {
								const selectedDataField = datafields[field.uuid] !== undefined ? datafields[field.uuid] : field.defaultField !== null ? field.defaultField : '';
								const selectedTitle = titles[field.uuid] !== undefined ? titles[field.uuid] : field.title;
								const selectedUnitType = unitTypes[field.uuid] !== undefined ? unitTypes[field.uuid] : field.unit;
								const selectedConversionFactor = conversionFactors[field.uuid] !== undefined ? conversionFactors[field.uuid] : field.conversionFactor;

								return (
									<>
										<FormControl fullWidth style={{ marginTop: 20 }} key={field.uuid}>
											<InputLabel id={'select-field-label-' + field.uuid}>{field.title} {t('package.datafield')}</InputLabel>

											<Select
												labelId={'select-field-label-' + field.uuid}
												id={'select-field-' + field.uuid}
												value={selectedDataField}
												label={field.title + ' ' + t('package.datafield')}
												onChange={(event) => handleFieldChange(event.target.value, field.uuid)}
											>
												{dataTypeFields.map(d => {
													return <MenuItem key={d.key} value={d.key}>{d.key}</MenuItem>;

												})}
											</Select>
										</FormControl>

										<Grid container spacing={0} columnSpacing={{ xs: 0, sm: 3 }}>
											<Grid item xs={12} sm={4}>
												<TextField
													id={"title" + field.uuid}
													label={t('package.title')}
													value={selectedTitle}
													variant="outlined"
													color="secondary"
													className={classes.textField}
													onChange={(event) => handleFieldTitleChange(event.target.value, field.uuid)}

												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<TextField
													id={"unittype" + field.uuid}
													label={t('package.unittype')}
													value={selectedUnitType}
													variant="outlined"
													color="secondary"
													className={classes.textField}
													onChange={(event) => handleFieldUnitChange(event.target.value, field.uuid)}
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<Box display="flex" alignItems="center">
													<TextField
														id={"conversionfactor" + field.uuid}
														label={t('package.conversionfactor')}
														value={selectedConversionFactor}
														variant="outlined"
														color="secondary"
														className={classes.textField}
														onChange={(event) => handleFieldConversionFactorChange(event.target.value, field.uuid)}
													/>
													{field.conversionFactorInfo ?
														<IconButton onClick={() => handleShowInfo(field.uuid)} style={{ marginTop: 15 }}>
															<InfoIcon fontSize="large" />
														</IconButton>
														: <></>}
												</Box>
											</Grid>
										</Grid>

										<Divider style={{ marginTop: 40, marginBottom: 20 }} />
									</>
								)
							})}

							<Dialog
								open={infoIsOpen}
								onClose={handleInfoClose}
							>
								<DialogContent>
									<DialogContentText>
										{infoText}
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleInfoClose} autoFocus variant="contained" color="primary">
										OK
									</Button>
								</DialogActions>
							</Dialog>
						</>
						: <></>
					}

					{alarms.length ? (
						<>
							<Typography variant='h5' align='center' style={{ marginTop: 30 }}>{t('package.recipients')}</Typography>

							<Grid container>
								{alarms.map(alarm => {
									return (
										<Grid item xs={12} key={alarm.uuid} style={{ marginTop: 20 }}>
											<Autocomplete
												freeSolo
												multiple
												id="users-autocomplete"
												options={alarmUsers}
												getOptionLabel={(option) => option.firstName + ' ' + option.lastName + ' - ' + option.email}
												defaultValue={alarmRecipients}
												renderTags={(value, getTagProps) => (
													value.map((option, index) => {
														let label = '';
														if (typeof option === 'object' && option.firstName?.length) {
															label = option.firstName + ' ' + option.lastName + ' - ' + option.email;
														} else if (typeof option === 'object' && !option.firstName?.length) {
															label = option.email;
														} else {
															label = option;
														}
														return <Chip variant="outlined" label={label} {...getTagProps({ index })} />
													})
												)}
												renderInput={(params) => (
													<TextField {...params} variant="outlined" error={alarmRecipientsError.length ? true : false} helperText={alarmRecipientsError} />
												)}
												onChange={(e, newValue) => handleRecipientsChange(e, newValue)}
											/>
										</Grid>
									)
								})}
							</Grid>
						</>
					) : <></>}
				</>
			) : <CircularLoader />}
		</OnboardContainer>
	)
}

export default OnboardChoosePackage;