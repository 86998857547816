import React, { useEffect, useState } from 'react';
import { Typography, TextField } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';

const OnboardChooseName = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		let activeStep = 3;
		if (state.bulkOnboarding) {
			activeStep = 2;
		}

		dispatch({ headerTitle: t('device.header'), showMobileStepper: true, showBottomNavigation: false, activeStep: activeStep });
	}, [dispatch, state.bulkOnboarding, t]);

	useEffect(() => {
		async function fetchData() {
			if (state.device) {
				if (state.selectedName) {
					setName(state.selectedName);
				} else {
					setName(state.device.data.name);
					dispatch({ selectedName: state.device.data.name })
				}

				if (state.selectedDescription) {
					setDesc(state.selectedDescription);
				} else {
					setDesc(state.device.data.description);
					dispatch({ selectedDescription: state.device.data.description })
				}
			} else {
				setName(state.uuname);
				dispatch({ selectedName: state.uuname })
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveName = (data) => {
		setName(data);

		dispatch({ selectedName: data });
	}

	const saveDesc = (data) => {
		setDesc(data);

		dispatch({ selectedDescription: data });
	}

	return (
		<OnboardContainer hasPadding={true} title={t('main.deviceId')} subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			<Typography variant="h3" align="center">{t('device.subheader')}</Typography>

			<Typography style={{ marginTop: 20 }}>{t('device.text')}</Typography>

			<TextField
				id="name"
				label={t('main.name')}
				value={name}
				variant="outlined"
				color="secondary"
				className={classes.textField}
				onChange={(event) => saveName(event.target.value)}
			/>

			<TextField
				multiline
				id="desc"
				label={t('main.desc')}
				value={desc}
				variant="outlined"
				color="secondary"
				className={classes.textField}
				onChange={(event) => saveDesc(event.target.value)}
				inputProps={{
					style: {
						minHeight: '100px',
						maxHeight: "200px",
					},
				}}
			/>
		</OnboardContainer>
	)
}

export default OnboardChooseName;