import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Box, Button } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';
import useGlobalState from 'hooks/useGlobalState';
import { getDeviceByUuname } from 'data/api';
import OnboardContainer from 'components/OnboardContainer';
import CircularLoader from 'components/ui/CircularLoader';
// import { defaultGlobalState } from 'providers/GlobalStateProvider';

const Onboard = () => {
	const classes = mainStyles();
	const params = useParams();
	const navigate = useNavigate();
	const [state, dispatch] = useGlobalState();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [device, setDevice] = useState(null);

	useEffect(() => {
		dispatch({ headerTitle: t('onboard.header'), activeStep: 0, showMobileStepper: false, showBottomNavigation: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		console.log('useEffect');

		async function fetchData() {
			const deviceData = await getDeviceByUuname(params.device);

			if (deviceData) {
				if (deviceData.status === 403 || deviceData.status === 404) {
					//not provisioned
				} else if (deviceData.status === 200) {
					setDevice(deviceData);

					dispatch({ device: deviceData });
				}
			}

			setLoading(false);
		}

		fetchData();
	}, [params.device, dispatch]);

	const handleConnect = () => {
		// if (params.device !== state.uuname) {
		// 	delete defaultGlobalState.user;
		// 	dispatch(defaultGlobalState);
		// 	localStorage.removeItem('globalState');
		// }

		dispatch({ uuname: params.device, deviceType: params.deviceType });

		if (state.bulkOnboarding) {
			navigate('/onboard/name');
		} else {
			navigate('/onboard/customer');
		}
	}

	const handleCancel = () => {
		navigate('/scan');
	}

	return (
		<OnboardContainer hasPadding={true} title={t('main.deviceId')} subtitle={params.device} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			{!loading ?
				<>
					<List style={{ padding: 0 }}>
						<ListItem style={{ padding: 0 }}>
							<ListItemText primary={t('main.deviceId')} secondary={params.device} />
						</ListItem>
						<ListItem style={{ padding: 0 }}>
							<ListItemText primary={t('onboard.deviceType')} secondary={params.deviceType ? params.deviceType : '-'} />
						</ListItem>
						<ListItem style={{ padding: 0 }}>
							<ListItemText primary={t('onboard.deviceStatus')} secondary={device === null ? t('onboard.notProvisioned') : t('onboard.provisioned')} />
						</ListItem>
					</List>

					<Typography style={{ marginTop: 10 }}>{t('onboard.connectText')}</Typography>

					<Box className={classes.buttonPanel}>
						<Button className={classes.greenButton} onClick={() => handleConnect()}>{t('onboard.connect')}</Button>
						<Button className={classes.redButton} onClick={() => handleCancel()}>{t('main.cancel')}</Button>
					</Box>
				</>
				: <CircularLoader />}
		</OnboardContainer>
	)
}

export default Onboard;