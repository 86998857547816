import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography, Menu, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import EditIcon from '@mui/icons-material/Edit';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import OnboardContainer from 'components/OnboardContainer';
import CircularLoader from 'components/ui/CircularLoader';
import { getLatestData } from 'data/api';
import useWindowSize from 'hooks/useWindowSize';

const DeviceDatafieldsList = () => {
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [latestData, setLatestData] = useState([]);
	const [selectedKey, setSelectedKey] = useState(null);

	useEffect(() => {
		dispatch({ headerTitle: t('main.datafields'), showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch, t]);

	useEffect(() => {
		async function fetchData() {
			if (state.onboardedDevice) {
				let newLatestData = [];

				await state.onboardedDevice.dataKeys.reduce(async (dataKeypromise, dataKey) => {
					await dataKeypromise;

					const d = await getLatestData(state.onboardedDevice.uuid, dataKey.key);

					if (d.status === 200 && d.data?.length) {
						newLatestData.push({ label: dataKey.key, value: d.data.pop()[dataKey.key], unit: dataKey.unit });
					}
				}, Promise.resolve());

				setLatestData(newLatestData);

				setLoading(false);
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.onboardedDevice.dataKeys.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		const rpp = parseInt(event.target.value, 10);
		setRowsPerPage(rpp);

		setPage(0);
	}

	const handleActionClick = (event, key) => {
		setSelectedKey(key);

		setAnchorEl(event.currentTarget);
	}

	const handleActionClose = () => {
		setAnchorEl(null);
		setSelectedKey(null);
	}

	// const handleEdit = () => {
	// 	navigate('/device/datafield/edit/' + selectedKey);

	// 	handleActionClose();
	// }

	const handleEditAlarms = () => {
		navigate('/device/alarm/' + selectedKey);

		handleActionClose();
	}

	return (
		<>
			<OnboardContainer title={state.onboardedDevice.name} subtitle={state.onboardedDevice.uuname} icon={<TimelineIcon />} cancelRoute="/device" style={{ height: windowSize.height !== undefined ? windowSize.height - 119 : 0 }}>
				{!loading ?
					<>
						<TableContainer sx={{ height: (windowSize.height - 300) }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell style={{ backgroundColor: '#D9D9D9' }}><Typography>{t('main.datafield')}</Typography></TableCell>
										<TableCell style={{ backgroundColor: '#D9D9D9' }}></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{state.onboardedDevice.dataKeys.map(key => {
										const d = latestData.find(d => d.label === key.key);

										return (
											<TableRow key={key.key}>
												<TableCell component="th" scope="row">
													<Typography>{key.key}</Typography>
													<Typography variant="body2">{d !== undefined ? (isNaN(d.value) ? '-' : new Intl.NumberFormat('da-DK').format(d.value)) : t('main.noData')}</Typography>
												</TableCell>
												<TableCell align="right"><MoreVertIcon onClick={(event) => handleActionClick(event, key.key)} /></TableCell>
											</TableRow>
										)
									})}

									{emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							style={{ backgroundColor: '#D9D9D9' }}
							rowsPerPageOptions={[5, 10, 25, { label: t('main.all'), value: -1 }]}
							component="div"
							count={state.onboardedDevice.dataKeys.length}
							rowsPerPage={rowsPerPage}
							labelRowsPerPage=""
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</>
					: (<CircularLoader />)}
			</OnboardContainer>


			<Menu
				id="vert-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleActionClose}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{/* <MenuItem onClick={handleEdit}>
					<ListItemIcon>
						<EditIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Rediger</ListItemText>
				</MenuItem> */}
				<MenuItem onClick={handleEditAlarms}>
					<ListItemIcon>
						<NotificationsActiveIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>{t('alarms.alarms')}</ListItemText>
				</MenuItem>
			</Menu>

		</>
	)
}

export default DeviceDatafieldsList;