import React, { useEffect, useState } from 'react';
import { Container, Paper, Card, CardHeader, Avatar, CardContent, Typography, TextField, Box, Button, IconButton, Snackbar, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import { addressLookup, locationLookup, addCollection, setCollectionPermissions } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const OnboardAddBuilding = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();
	// const mapRef = useRef();
	const { t } = useTranslation();

	const [errorAlertOpen, setErrorAlertOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [gpsLoading, setGpsLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [address, setAddress] = useState('');
	const [addressError, setAddressError] = useState('');
	const [location, setLocation] = useState([]);
	const [mapPosition, setMapPosition] = useState([56.26392, 9.501785]);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: t('buildingadd.header'), showMobileStepper: false, showBottomNavigation: false });
	}, [dispatch, t]);

	useEffect(() => {
		//leaflet hack to fix marker images
		delete L.Icon.Default.prototype._getIconUrl;

		L.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
			iconUrl: require('leaflet/dist/images/marker-icon.png').default,
			shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
		});
	}, []);

	const handleGetLocation = async () => {
		setGpsLoading(true);

		navigator.geolocation.getCurrentPosition(async (position) => {
			setMapPosition([position.coords.latitude, position.coords.longitude]);
			setLocation([position.coords.latitude, position.coords.longitude]);

			const lookupResult = await locationLookup(position.coords.latitude, position.coords.longitude);

			if (lookupResult?.betegnelse) {
				setAddress(lookupResult.betegnelse);
			}

			setGpsLoading(false);
		}, error => {
			if (error.code === 1) {
				alert(t('buildingadd.gpsError'));
			} else {
				alert(t('buildingadd.error'));
			}

			setGpsLoading(false);
		});
	}

	const handleAddressLookup = async () => {
		setAddressError('');

		if (!address.length) {
			setAddressError(t('buildingadd.addressError'));
		} else {
			const addresLookupResult = await addressLookup(address);
			if (!addresLookupResult || !addresLookupResult.length) {
				setAddressError(t('buildingadd.addressLocationError'));
			} else {
				setMapPosition([addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][1], addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][0]]);
				setLocation([addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][1], addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][0]]);
			}
		}
	}

	const handleCancel = () => {
		navigate('/onboard/building');
	}

	const handleSave = async () => {
		setNameError('');
		setAddressError('');

		let isOK = true;
		let saveLocation = null;

		if (!name.length) {
			setNameError(t('buildingadd.nameError'));
			isOK = false
		} else if (address.length && !location.length) {
			const addresLookupResult = await addressLookup(address);

			if (!addresLookupResult || !addresLookupResult.length) {
				setAddressError(t('buildingadd.addressLocationError'));

				isOK = false;
			} else {
				setLocation([addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][1], addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][0]]);
				saveLocation = addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][1] + ',' + addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][0];
			}
		}

		if (isOK) {
			setLoading(true);

			const data = {
				parent: null,
				kind: 'c789f037-d40a-4f0e-8779-275710cdfdff',
				name: name,
				address: address,
				latlong: saveLocation ? saveLocation : location.length ? location.join(',') : '',
				org: state.user.org.uuid,
			};
			console.log(data);

			let result = await addCollection(data);

			if (!result) {
				setLoading(false);
				setErrorAlertOpen(true);
			} else {
				let visibleTo = ['74f33b7e-4394-4153-9635-f584197b2fe6', state.user.org.uuid];

				if (!visibleTo.includes(state.selectedCustomer)) {
					visibleTo.push(state.selectedCustomer);
				}

				await setCollectionPermissions(result.uuid, visibleTo);

				dispatch({ selectedBuilding: result.uuid });

				setLoading(false);

				navigate('/onboard/building');
			}
		}
	}

	const handleAlertClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setErrorAlertOpen(false);
	};

	return (
		<Container component="main" maxWidth="lg" className={classes.container}>
			<Card elevation={0}>
				<CardHeader
					avatar={
						<Avatar>
							<QrCodeScannerIcon htmlColor="#ED1C24" />
						</Avatar>
					}
					title={t('main.deviceId')}
					titleTypographyProps={{ textTransform: 'uppercase' }}
					subheader={state.uuname}
				/>
				<CardContent>
					<Paper className={classes.paper}>
						<Typography variant="h3" align="center">{t('buildingadd.subheader')}</Typography>

						<Typography style={{ marginTop: 20 }}>{t('buildingadd.text')}</Typography>

						{!loading ? (
							<>
								<Box className={classes.buttonPanel} justifyContent="center">
									<LoadingButton
										className={classes.greenButtonNoWidth}
										onClick={() => handleGetLocation()}
										startIcon={<LocationSearchingIcon />}
										loading={gpsLoading}
										loadingPosition="start"
									>
										{t('buildingadd.getLocation')}
									</LoadingButton>
								</Box>

								<TextField
									id="name"
									label={t('main.name')}
									value={name}
									variant="outlined"
									color="secondary"
									className={classes.textField}
									onChange={(event) => setName(event.target.value)}
									error={nameError.length ? true : false}
									helperText={nameError}
								/>

								<TextField
									id="name"
									label={t('buildingadd.address')}
									value={address}
									variant="outlined"
									color="secondary"
									className={classes.textField}
									style={{ width: 'calc(100% - 45px)' }}
									onChange={(event) => setAddress(event.target.value)}
									error={addressError.length ? true : false}
									helperText={addressError}
								/>
								<IconButton
									variant='filled'
									style={{ marginTop: '25px', marginLeft: '5px' }}
									onClick={() => handleAddressLookup()}>
									<LocationSearchingIcon />
								</IconButton>

								<MapContainer center={mapPosition} zoom={6} style={{ width: '100%', height: '250px', marginTop: 30 }}>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
									<Marker position={mapPosition} />
								</MapContainer>

								<Box className={classes.buttonPanel}>
									<Button className={classes.redButton} onClick={() => handleCancel()}>{t('main.cancel')}</Button>
									<Button className={classes.greenButton} onClick={() => handleSave()}>{t('main.save')}</Button>
								</Box>
							</>
						) : (<CircularLoader />)}
					</Paper>
				</CardContent>
			</Card>

			<Snackbar open={errorAlertOpen} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
					{t('buildingadd.saveError')}
				</Alert>
			</Snackbar>
		</Container>
	)
}

export default OnboardAddBuilding;