import { makeStyles } from '@mui/styles';

const mainStyles = makeStyles(theme => ({
	appBarWrapper: {
		flexGrow: 1,
	},
	appBar: {
		height: '70px',
	},
	appBarTitle: {
		flexGrow: 1,
		position: 'relative',
		textAlign: 'right',
	},
	logoContainer: {
		height: '90px',
	},
	logo: {
		flexGrow: 1,
		width: '50%',
	},
	logoHeader: {
		flexGrow: 1,
		width: '160px',
	},
	bottomNavigation: {
		backgroundColor: '#898584 !important',
	},
	container: {
		// height: 'calc(100vh - 230px)',
		padding: '0px !important',
		overflow: 'scroll',
		// border: '1px solid red',
	},
	containerWithPadding: {
		// height: 'calc(100vh - 200px)',
	},
	paper: {
		padding: '30px',
		backgroundColor: '#F7F7F7 !important',
	},
	paperLessPadding: {
		padding: '15px',
		backgroundColor: '#F7F7F7 !important',
	},
	paperNoPadding: {
		padding: 0,
		backgroundColor: '#F7F7F7 !important',
	},
	buttonPanel: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	greenButton: {
		width: '130px',
		height: '45px',
		color: '#fff !important',
		backgroundColor: '#4fb885 !important'
	},
	greenButtonNoWidth: {
		height: '45px',
		color: '#fff !important',
		backgroundColor: '#4fb885 !important'
	},
	redButton: {
		width: '130px',
		height: '45px',
		color: '#fff !important',
		backgroundColor: '#f1787d !important'
	},
	progressBar: {
		backgroundColor: '#E2E2EA !important'
	},
	progressBarActive: {
		backgroundColor: '#3DD598 !important',
		color: 'red'
	},
	searchFieldInput: {
		height: '50px',
		"&:placeholder": {
			color: "green",
		}
	},
	searchFieldInputLabel: {
		color: '#000',
	},
	searchField: {
		width: '100%',
		marginTop: '10px !important',
		marginBottom: '10px !important',
		backgroundColor: '#d9d9d9',
	},
	textField: {
		width: '100%',
		marginTop: '20px !important',
	},
	greyButtonBox: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '70px',
		backgroundColor: '#d9d9d9 !important',
		color: '#000000 !important',
		borderRadius: '10px',
	},
	userimage: {
		float: 'left',
		borderRadius: '50%',
		height: 45,
		width: 45,
	},
	drawerPaper: {
		backgroundColor: '#D9D9D9 !important'
	},
	periodButton: {
		border: 'solid 2px #377EB8 !important',
		borderRadius: 4,
		color: '#377EB8 !important',
		marginRight: '10px !important',
		textTransform: 'none',
		"&:hover": {
			backgroundColor: '#377EB8 !important',
			color: '#fff !important'
		}
	},
	periodButtonActive: {
		border: 'solid 2px #377EB8 !important',
		borderRadius: 4,
		color: '#fff !important',
		backgroundColor: '#377EB8 !important',
		marginRight: '10px !important',
		textTransform: 'none',
		"&:hover": {
			backgroundColor: '#377EB8 !important',
			color: '#fff !important'
		}
	},
	graphDatePickers: {
		width: '100%',
		marginTop: 15,
		[theme.breakpoints.up('md')]: {
			height: 100,
		},
	},
	graphDatePickersDesc: {
		fontSize: '1.2rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem',
		}
	},
}));

export default mainStyles;